// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { ICellRendererParams } from "ag-grid-community";
import { Button, Dropdown } from "antd";
import React, { Component } from "react";
import { ButtonGridProps, DropdownButtonGridProps, isButtonGrid } from "./interfaces/button-props";

interface ButtonsRendererProps extends ICellRendererParams {
    buttons: Array<ButtonGridProps | DropdownButtonGridProps>;
    className?: string;
}

class ButtonsRenderer extends Component<ButtonsRendererProps> {  

    constructor(props: ButtonsRendererProps) {
        super(props);
    }

    render() {
        const { buttons, className } = this.props;

        return (
            <span className={className}>
                {
                    buttons.map((item, i) => (
                        item.onRender === undefined || item.onRender(this.props)) ?
                            isButtonGrid(item) ?
                                <Button
                                    key={i}
                                    disabled={item.getDisabled ? item.getDisabled() : false}
                                    {...item.buttonProps}
                                    onClick={() => item.onClick(this.props)}
                                />
                                :
                                <Dropdown.Button
                                    key={i}
                                    disabled={item.getDisabled ? item.getDisabled() : false}
                                    {...item.dropdownProps}
                                    onClick={() => item.onClick(this.props)}
                                    menu={item.menu}
                                />
                            : ""
                    )
                }
            </span>
        );
    }
}

export default ButtonsRenderer;
export type { ButtonsRendererProps }