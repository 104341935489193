// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component, ReactNode, MouseEventHandler, createRef } from 'react';
import styles from './styles/cloud.less';
import clsx from "clsx";

interface Props {
    className?: string;
    title?: string;
    intro?: ReactNode;
    hover?: boolean;
    highlight?: boolean;
    children?: ReactNode;
    buttonFooter?: ReactNode;
    header?: ReactNode;
    highlightFooter?: ReactNode;
    onClick?: MouseEventHandler<HTMLElement>;
    style?: React.CSSProperties; 
}

class Cloud extends Component<Props> {
    containerRef = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
    }

    scrollIntoView = () => {
        this.containerRef.current?.scrollIntoView();
    }

    render() {
        const { className, style, children, title, intro, hover, highlight, buttonFooter, header, highlightFooter, onClick } = this.props;

        return (
            <div
                ref={this.containerRef}
                className={clsx(className, styles.cloudContainer, {
                    [styles.cloudContainerHover]: hover,
                    [styles.cloudHighlight]: highlight,
                })}
                style={style}
                onClick={onClick}
            >
                { header && (<div className={styles.highlightHeader}>{header}</div>) }
                { title && (<h2 className={styles.mainTitle}>{title}</h2>) }
                { intro && (<div className={styles.topContent}>{intro}</div>) }
                {
                    React.Children.count(children) > 0 &&
                    <div className={styles.content}>
                        {children}
                    </div>
                }
                { buttonFooter && (<div className={styles.footer}>{buttonFooter}</div>) }
                { highlightFooter && (<div className={styles.highlightFooter}>{highlightFooter}</div>) }
            </div>
        )
    }
}

export default Cloud
