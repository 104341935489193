// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Checkbox, Col, Empty, Row, Spin, Tabs, Tag, Typography } from "antd";
import { TFunction } from "i18next";
import React, { Component, ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MdStar, MdStarBorder } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroller";
import { permissionLookup } from "security";
import { UserModel } from "user";
import { EventStatus, serviceClient } from "web-service";
import pageList from "../data/page-list";
import SearchItem, { SearchType } from "../data/search-item";
import BookingDetailsPane from "./booking-details-pane";
import styles from "./styles/search-pane.less";
import clsx from "clsx";

const pageSize = 30;
const deactivatedEventNumbers = ["Cancelled", "Abandoned"];

interface Props extends WithTranslation {
    type?: "Events" | "Reservations" | "Pages" | null;
    user: UserModel;
    filter: string;
    eventStatus?: EventStatus | undefined;
}

interface State {
    tab: string;
    page: number;
    hasMore: boolean;
    abandonedRooms: boolean;
    all: Array<ReactNode>;
    events: Array<ReactNode>;
    reservations: Array<ReactNode>;
    pages: Array<ReactNode>;
    pageSource: Array<SearchItem>;
}

class SearchPane extends Component<Props, State> {

    searchTimeOut: number | null = null;
    searchController: AbortController | null = null;

    constructor(props: Props) {
        super(props);

        let tab: string = "0";
        //Initial load in
        switch (props.type) {
            case "Events":
                tab = "1";
                break;
            case "Reservations":
                tab = "2";
                break;
            case "Pages":
                tab = "3";
                break;
        }

        this.state = {
            tab,
            page: 0,
            hasMore: true,
            abandonedRooms: false,
            all: [<Spin className={styles.spin} key={0} />],
            events: [<Spin className={styles.spin} key={0} />],
            reservations: [<Spin className={styles.spin} key={0} />],
            pages: [<Spin className={styles.spin} key={0} />],
            pageSource: pageList(props.user, props.t),
        };

        if (props.type == null || props.type == "Pages") {
            serviceClient.post("Report", "Search", {
                eventID: props.user.event == null ? "" : props.user.event.id
            }).promise.then((result) => {
                this.setState({ pageSource: this.state.pageSource.concat(result.items) });
            });
        }

        this.triggerSearch();
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.filter !== this.props.filter || prevProps.eventStatus != this.props.eventStatus) {
            this.setState({ page: 0 });
            this.triggerSearch();
        }
    }

    layoutItems = (array: Array<SearchItem>, isPages?: boolean) => {
        let result = [];

        for (let i = 0; i < array.length; i++) {
            let url = "", resultClass = "";


            url += this.props.user.baseUrl;


            switch (array[i].type) {
                case SearchType.Events:
                    url += "/Event/Set/";
                    resultClass = styles.rowResultsEvent;
                    break;
                case SearchType.Reservations:
                    url += "/Booking/Group/";
                    resultClass = styles.rowResultsReservations;
                    break;
                case SearchType.Reports:
                    url += "/Report/Display/";
                    resultClass = styles.rowResultsPages;
                    break;
                case SearchType.Pages:
                    resultClass = styles.rowResultsPages;
                    break;
            }

            url += array[i].id;
            let key = i + (isPages ? -1000 : (this.state.page * pageSize));

            const { t, user } = this.props;
            result.push(
                <Col className={styles.rowResultsBackground} span={24} key={key}>
                    <Row justify="space-between" className={clsx(styles.rowResults, resultClass)}>
                        <Col className={styles.colResultsTag}>
                            {this.getTypeDescription(array[i].type, t)}
                        </Col>
                        <Col className={styles.colResultsMain}>
                            <Row>
                                <Col>
                                    <a href={url} className={styles.contentTitle}>
                                        {
                                            deactivatedEventNumbers.includes(array[i].number)
                                                ? <s>{array[i].name}</s>
                                                : array[i].name
                                        }
                                    </a>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={styles.contentSmall}>
                                    <Typography.Text strong={array[i].type === SearchType.Reservations}>{array[i].description}</Typography.Text>
                                    {
                                        array[i].description3 &&
                                        <>
                                            <br />
                                            <span dangerouslySetInnerHTML={{ __html: array[i].description3 ?? "" }} />
                                        </>
                                    }
                                    {
                                        array[i].description2 &&
                                        <>
                                            <br />
                                            <span dangerouslySetInnerHTML={{ __html: array[i].description2 ?? "" }} />
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col className={clsx(styles.colResultsSub, {
                            [styles.reservationSub]: array[i].type === SearchType.Reservations
                        })}>
                            <Row justify="end"> 
                                <Col className={styles.idTitle}>
                                    {
                                        array[i].type === SearchType.Reservations
                                            ? <Tag className={clsx(styles.statusTag, styles[array[i].number.toLowerCase() as keyof typeof styles])}>
                                                {t(array[i].number)}
                                            </Tag>
                                            : <Typography.Text strong>
                                                {array[i].detail}
                                            </Typography.Text>
                                    }
                                </Col>
                            </Row>
                            <Row justify="end">
                                <Col className={styles.contentSmall}>
                                    {
                                        array[i].type === SearchType.Events &&
                                        <span>{t("eventId")}: {array[i].number}</span>
                                    }
                                    {
                                        array[i].type === SearchType.Reservations &&
                                        <a href={url} className={styles.reservationId}>{array[i].detail}</a>
                                    }
                                </Col>
                            </Row>
                            <div className={clsx(array[i].type === SearchType.Reservations && styles.reservationSpacer)}  />
                        </Col>
                        {
                            array[i].type == 1 &&
                            <Col className={styles.colFavourite}>
                                <a
                                    onClick={() => this.setFavorite(array[i])}
                                    className={clsx(styles.favorite, array[i].favorite ? styles.favoriteYes : styles.favoriteNo)}
                                >
                                    {array[i].favorite ? <MdStar /> : <MdStarBorder />}
                                </a>
                            </Col>
                        }
                    </Row>
                    {
                        array[i].type === SearchType.Reservations &&
                        <Row key={"viewDetails-" + array[i].id}>
                            <Col span={24} style={{ position: "unset" }}>
                                <BookingDetailsPane user={user} bookingGroupID={array[i].id} hasAbandonedRooms={this.state.abandonedRooms} />
                            </Col>
                        </Row>
                    }
                </Col>
            );
        }

        return result;
    }

    getTypeDescription(type: number, t: TFunction) {
        switch (type) {
            case 0:
                return <Tag className={styles.tag}>
                    {t("all")}
                </Tag>;
            case 1:
                return <Tag className={clsx(styles.tag, styles.tagEvents)}>
                    {t("event")}
                </Tag>;
            case 2:
                return <Tag className={clsx(styles.tag, styles.tagReservations)}>
                    {t("reservationsShort")}
                </Tag>;
            case 3:
                return <Tag className={clsx(styles.tag, styles.tagPages)}>
                    {t("report")}
                </Tag>;
            case 4:
                return <Tag className={clsx(styles.tag, styles.tagPages)}>
                    {t("page")}
                </Tag>;
        }

        return null;
    }

    triggerSearch = () => {
        let { filter, eventStatus } = this.props;

        if (this.searchTimeOut != null) {
            clearTimeout(this.searchTimeOut)
        }

        if (this.searchController != null) {
            this.searchController.abort();
        }

        this.searchTimeOut = setTimeout((async () => {
            let tab = this.state.tab;
            let data = [];

            if (tab !== "3") {

                let searchResult = serviceClient.post("General", "Search", {
                    eventID: this.props.user.event == null ? "" : this.props.user.event.id,
                    page: this.state.page,
                    filter: filter,
                    eventStatus: eventStatus,
                    type: tab,
                    abandonedRooms: this.state.abandonedRooms
                });

                this.searchController = searchResult.controller;

                data = await searchResult.promise;
            }

            switch (tab) {
                case "0": //All
                    let all = this.state.page == 0 ? [] : this.state.all;

                    all = all.concat(this.layoutItems(data.items));

                    if (!data.more)
                        all = all.concat(this.layoutItems(this.getFilteredPages(this.state.pageSource, filter), true));

                    this.setState({
                        all,
                        hasMore: data.more
                    });
                    break;
                case "1": //Events
                    let events = this.state.page == 0 ? [] : this.state.events;

                    this.setState({
                        events: events.concat(this.layoutItems(data.items)),
                        hasMore: data.more
                    });
                    break;
                case "2": //Reservations
                    let reservations = this.state.page == 0 ? [] : this.state.reservations;

                    this.setState({
                        reservations: reservations.concat(this.layoutItems(data.items)),
                        hasMore: data.more
                    });
                    break;
                case "3": //Reports/Pages
                    let pages = this.state.page == 0 ? [] : this.state.pages;

                    this.setState({
                        pages: pages.concat(this.layoutItems(this.getFilteredPages(this.state.pageSource, filter), true)),
                        hasMore: false
                    });
                    break;
            }

            this.setState({ page: this.state.page + 1 });

        }) as TimerHandler, 400);
    }

    handleTabClick = (key: string) => {
        this.setState({ tab: key, page: 0 });
        this.triggerSearch();
    }

    // returns each filter page
    getFilteredPages = (pageSource: Array<SearchItem>, filter: string) => {
        const filteredPages = [];

        for (let i = 0; i < pageSource.length; i++) {

            if (pageSource[i].name.toLowerCase().includes(filter.toLowerCase()))
                filteredPages.push(pageSource[i]);
        }

        return filteredPages;
    }

    setFavorite = (item: SearchItem) => {
        serviceClient.post("Favorite", "Event", {
            id: item.id,
            set: !item.favorite
        }).promise.then(() => {
            this.setState({ page: 0 });
            this.triggerSearch();
        });
    }

    handleAbandonedRoomsFilter = () => {
        this.setState({ abandonedRooms: !this.state.abandonedRooms });
        this.handleTabClick(this.state.tab);
    }

    render() {
        const { type, t, user } = this.props;
        return (
            <Tabs
                tabBarStyle={!type ? {} : { display: "none" }}
                defaultActiveKey="0"
                onTabClick={this.handleTabClick}
                className={styles.tabsContainer}
                tabBarExtraContent={
                    !type &&
                    <div className={styles.abandonedRoom}>
                        <Checkbox
                            onClick={this.handleAbandonedRoomsFilter}
                            checked={this.state.abandonedRooms}
                        >
                            {t("abandonedRooms")}
                        </Checkbox>
                    </div>
                }
                items={[
                    ...(!type ? [{
                        key: "0",
                        label: t("allResults"),
                        className: styles.tabPane,
                        children: (
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.triggerSearch}
                                hasMore={this.state.hasMore}
                                useWindow={false}
                                initialLoad={false}
                            >
                                {this.state.all.length == 0 ? <Empty description={t("emptyResults")} key="0" /> : this.state.all}
                            </InfiniteScroll>
                        )
                    }] : []),
                    ...(!type || type === "Events" ? [{
                        key: "1",
                        label: t("events"),
                        className: styles.tabPane,
                        children: (
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.triggerSearch}
                                hasMore={this.state.hasMore}
                                useWindow={false}
                                initialLoad={false}
                            >
                                {this.state.events.length == 0 ? <Empty description={t("emptyResultsEvents")} key="0" /> : this.state.events}
                            </InfiniteScroll>
                        )
                    }] : []),
                    ...((user.clientPermissions.includes(permissionLookup.RESERVATION_EDIT) ||
                        (user.event != null && user.event.eventPermissions.includes(permissionLookup.RESERVATION_EDIT))) &&
                    (type == null || type == "Reservations") ? [{
                        key: "2",
                        label: t("reservations"),
                        className: styles.tabPane,
                        children: (
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.triggerSearch}
                                hasMore={this.state.hasMore}
                                useWindow={false}
                                initialLoad={false}
                            >
                                {this.state.reservations.length == 0 ? <Empty description={t("emptyResultsReservations")} key="0" /> : this.state.reservations}
                            </InfiniteScroll>
                        )
                    }] : []),
                    ...(!type || type === "Pages" ? [{
                        key: "3",
                        label: t("pagesReports"),
                        className: styles.tabPane,
                        children:  <>{this.state.pages.length == 0 ? <Empty description={t("emptyResults")} key="0" /> : this.state.pages}</>
                    }] : [])
                ]}
            />
        );
    }
}

export default withTranslation("menu")(SearchPane);