// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { ColDef, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { Button, ButtonProps, Col, Modal, Row, Spin } from "antd";
import { constants } from "configuration";
import React, { Component, Key } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { UserModel } from "user";
import { Currency, TaxLevel, TaxType } from "web-service";
import { ButtonsRenderer } from "../button";
import GridSelector from "../grid-selector";
import ReservationWarningView from "../reservation-warning-view";
import FeeEdit, { DisplayType } from "./fee-edit";
import { PendingEventTax } from "./interfaces/event-taxes";
import { EventFeeTaxedRenderer, EventTaxAmountRenderer, EventTaxNameRenderer } from "./renderers";
import styles from "./styles/tax-list.less";

interface TaxUpdatePair {
    tax: PendingEventTax;
    updateReservation: boolean;
}

interface Props extends WithTranslation {
    type: DisplayType;
    user: UserModel;
    selectedTaxes: Array<number>;
    recalculateTaxKeys: Array<number>;
    fees: Array<PendingEventTax>;
    taxes: Array<PendingEventTax>;
    hasReservations: boolean;

    onSelect: (items: Array<TaxUpdatePair>) => void;
    onGet: (eventTaxID: string | undefined) => Promise<PendingEventTax>;
    onDelete: (tax: PendingEventTax, updateReservation: boolean) => boolean;
    onEdit: (tax: PendingEventTax, updateReservation: boolean) => boolean;

    currency: Currency;
    processing?: boolean;
}

interface State {
    mode: "grid" | "edit";
    activeTax?: PendingEventTax;
    activeTaxModals: Array<TaxUpdatePair>;
    feesColumnDefs: Array<ColDef>;
    frameworkComponents: any;
    loading: boolean;
}

class FeeList extends Component<Props, State> {
    singleLocation?: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);

        const { t, user } = this.props;

        this.state = {
            mode: "grid",
            activeTax: undefined,
            activeTaxModals: [],
            feesColumnDefs: [
                {
                    checkboxSelection: (params) => {
                        const tax: PendingEventTax = params.data;

                        return (tax.eventTax.hotelSpecific || tax.eventTax.taxType === TaxType.SubBlockFee);

                    },
                    headerCheckboxSelection: true,
                    headerName: t("fee"),
                    cellRenderer: "EventTaxNameRenderer",
                    cellStyle: {
                        fontWeight: 500,
                    },
                },
                {
                    headerName: t("feeAmount"),
                    cellStyle: {
                        fontWeight: 500,
                    },
                    cellRenderer: "EventTaxAmountRenderer",
                    cellRendererParams: {
                        currency: user?.event?.currency
                    }
                },
                {
                    headerName: t("taxed"),
                    cellStyle: {
                        fontWeight: 500,
                    },
                    cellRenderer: "EventFeeTaxedRenderer"
                },
                {
                    headerName: t("properties"),
                    field: "eventTax.level",
                    cellStyle: {
                        fontWeight: 500,
                    },
                    valueGetter: (params: ValueGetterParams) => {
                        const tax: PendingEventTax = params.data;

                        switch (tax.eventTax.level) {
                            case TaxLevel.PerReservation:
                                return t("taxPerReservation");
                            case TaxLevel.PerRoomNight:
                                return t("taxPerRoomNight");
                        }
                    }
                },
                {
                    headerName: t("actions"),
                    cellRendererSelector: (params: ICellRendererParams) => {
                        const { selectedTaxes, processing } = this.props;
                        const tax: PendingEventTax = params.data;

                        if (tax.eventTax.id === null)
                            return {};
                        else {
                            let moveText: React.ReactNode = "";
                            const feeForAll = !tax.eventTax.hotelSpecific && tax.eventTax.taxType !== TaxType.SubBlockFee;

                            const buttonProps: ButtonProps = {
                                disabled: feeForAll || processing
                            };

                            if (selectedTaxes.includes(tax.key) || feeForAll) {
                                moveText = <ArrowLeftOutlined />;
                                buttonProps.danger = true;

                            } else {
                                moveText = <ArrowRightOutlined />;
                                buttonProps.type = "primary";
                            }

                            return {
                                component: "ButtonsRenderer",
                                params: {
                                    buttons: [
                                        {
                                            buttonProps: {
                                                children: t("edit"),
                                                type: "primary",
                                                className: styles.gridButton,
                                                disabled: processing
                                            },
                                            onClick: () => this.handleModeEdit(tax),
                                        },
                                        {
                                            buttonProps: {
                                                ...buttonProps,
                                                children: moveText,
                                                className: styles.gridButton,
                                            },
                                            onClick: this.handleShowModal,
                                        },
                                    ]
                                }
                            }
                        }
                    },
                    cellStyle: {
                        fontWeight: 500,
                    },
                }
            ],
            frameworkComponents: {
                EventTaxNameRenderer,
                EventTaxAmountRenderer,
                EventFeeTaxedRenderer,
                ButtonsRenderer
            },
            loading: false
        }
    }

    // Add/Edit options
    handleEditCancel = () => {
        this.setState({ mode: "grid" });
    }
    handleDelete = (tax: PendingEventTax, updateReservation: boolean) => {
        if (this.props.onDelete(tax, updateReservation))
            this.setState({ mode: "grid" });
    }
    handleEdit = (tax: PendingEventTax, updateReservation: boolean) => {
        if (tax) {
            this.setState({
                loading: true
            }, async () => {
                if (this.props.onEdit(tax, updateReservation)) {
                    this.setState({
                        mode: "grid",
                        loading: false
                    });
                }
            });
        }
    }

    handleShowModal = (params: ICellRendererParams) => {
        const data: PendingEventTax = params.data;
        const { recalculateTaxKeys, onSelect, hasReservations } = this.props;
        const { activeTaxModals } = this.state;

        const tax = activeTaxModals.find((t) => t.tax.eventTax.id === data.eventTax.id);
        const currentValue = recalculateTaxKeys.includes(data.key);
        if (tax)
            tax.updateReservation = currentValue;
        else if (hasReservations)
            activeTaxModals.push({ tax: data, updateReservation: currentValue });
        else
            onSelect([{ tax: data, updateReservation: currentValue }]);

        this.setState({ activeTaxModals });
    }

    // Grid options
    handleModeEdit = (rowItem?: PendingEventTax) => {

        if (rowItem) {
            this.setState({ mode: "edit", activeTax: rowItem });
        } else {
            this.props.onGet(undefined)
                .then((eventTax: PendingEventTax) => {
                    if (eventTax) {
                        this.setState({ mode: "edit", activeTax: eventTax });
                    }
                });
        }
    }
    handleGetKey = (rowItem: PendingEventTax) => {
        return (rowItem.key);
    }
    handleChange = () => {
        this.props.onSelect(this.state.activeTaxModals);

        this.setState({ activeTaxModals: [] });
    }
    handleChangeModal = (keys: Array<Key>) => {
        const { fees, recalculateTaxKeys, onSelect, hasReservations } = this.props;
        const selectedTaxKeys = keys.map(i => Number(i));
        const relevantTaxes = fees
            .filter(t => selectedTaxKeys.includes(t.key)
                && (t.eventTax.hotelSpecific || t.eventTax.taxType === TaxType.SubBlockFee))
            .map(t => { return { tax: t, updateReservation: false } });

        if (hasReservations) {
            relevantTaxes.forEach(r => r.updateReservation = recalculateTaxKeys.includes(r.tax.key));
            this.setState({ activeTaxModals: relevantTaxes });
        }
        else {
            onSelect(relevantTaxes);
        }
    }

    handleFeeDataList = (keys: Array<Key>, selected: boolean) => {
        const { fees } = this.props;

        return fees
            .filter((t) => {
                const isIncluded = keys.includes(t.key);

                if (!t.eventTax.hotelSpecific && t.eventTax.taxType !== TaxType.SubBlockFee)
                    return selected;
                else
                    return isIncluded === selected;
            });
    }


    render() {
        const { selectedTaxes, user, t, currency, processing, hasReservations, taxes, type } = this.props;
        const { mode, activeTax, feesColumnDefs, frameworkComponents, activeTaxModals } = this.state;
        const { handleEdit, handleDelete, handleEditCancel } = this;

        const isExistingTax = (activeTax && activeTax.eventTax.id !== constants.GUID_EMPTY);

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    {(mode === "grid")
                        ?
                        <div>
                            <div>
                                <Row justify="space-between">
                                    <Col>
                                        <h2 className={styles.taxTitle}>{t("feesHeader")}</h2>
                                    </Col>
                                    <Col>
                                        <Button
                                            disabled={processing}
                                            onClick={() => this.handleModeEdit()}
                                            type="primary"
                                            size="small"
                                        >
                                            {t("createNewFeeButton")}
                                        </Button>
                                    </Col>
                                </Row>

                                {
                                    type === DisplayType.Hotel ?
                                        <p className={styles.taxText}>{t("feesHeaderText")}</p>
                                        : <p className={styles.taxText}>{t("subBlockFeesHeaderText")}</p>
                                }
                            </div>
                            <GridSelector
                                unselectedTitle={t("allAvailableFees")}
                                selectedTitle={t("appliedFees")}
                                unselectedEmpty={t("noneAvailable")}
                                selectedEmpty={t("noneSelected")}
                                addText={t("add")}
                                removeText={t("remove")}
                                onGridData={this.handleFeeDataList}
                                onGetKey={this.handleGetKey}
                                onChange={this.handleChangeModal}
                                onSelect={(selected: PendingEventTax[]) => {
                                    this.handleChangeModal(selected.map(s => s.key));
                                }}
                                onDeselect={(selected?: PendingEventTax[]) => {
                                    if (selected === undefined) {
                                        return;
                                    }
                                    const { selectedTaxes } = this.props;
                                    const keys = selected.map(s => s.key);
                                    this.handleChangeModal(selectedTaxes.filter(t => keys.includes(t)));
                                }}
                                columnDefs={feesColumnDefs}
                                frameworkComponents={frameworkComponents}
                                value={selectedTaxes}
                                sortable={false}
                                disabled={processing}
                            />
                        </div>
                        : ""
                    }
                    {(mode === "edit")
                        ?
                        <div>
                            <h2 className={styles.taxTitle}>{isExistingTax ? t("editFee") : t("createNewFee")}</h2>
                            {
                                type === DisplayType.Hotel ?
                                    <p className={styles.taxText}>{t("feesHeaderText")}</p>
                                    : <p className={styles.taxText}>{t("subBlockFeesHeaderText")}</p>
                            }
                            <FeeEdit
                                processing={processing}
                                user={user}
                                currency={currency}
                                selectedEventTax={activeTax}
                                updateTax={handleEdit}
                                deleteTax={handleDelete}
                                cancelTax={handleEditCancel}
                                taxes={taxes}
                                type={type}
                            />
                        </div>
                        : ""
                    }

                    <Modal
                        title={t("moveFee")}
                        open={activeTaxModals.length > 0}
                        onOk={this.handleChange}
                        okText={t("shared:yes")}
                        okType={"danger"}
                        okButtonProps={({ type: "primary" })}
                        onCancel={() => { this.setState({ activeTaxModals: [] }) }}
                        cancelText={t("shared:no")}
                    >
                        {
                            hasReservations &&
                            activeTaxModals.map((t, ix) => {
                                const warning = t.tax.eventTax.reservationWarning;

                                return (
                                    <div key={ix}>
                                        {t.tax.eventTax.translation.name}
                                        {
                                            warning &&
                                            <ReservationWarningView
                                                checked={t.updateReservation}
                                                onChange={() => {
                                                    t.updateReservation = !t.updateReservation;

                                                    this.setState({ activeTaxModals });
                                                }}
                                                reservationWarning={warning}
                                                mode={"edit"}
                                                hasReservations={hasReservations}
                                            />
                                        }
                                    </div>);
                            })
                        }

                    </Modal>
                </Spin>
            </div>
        )
    }
}

export default withTranslation(["event"])(FeeList);
