// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { ICellRendererParams } from "ag-grid-community";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MdCheckCircle } from "react-icons/md";
import { Tooltip } from "antd";
import styles from "./styles/event-tax-name-renderer.less";
import { PendingEventTax } from "../interfaces/event-taxes";

interface Props extends ICellRendererParams, WithTranslation {
   
}

class EventFeeTaxedRenderer extends Component<Props> {
    render() {
        const { t } = this.props;
        const value: PendingEventTax = this.props.data;

        const title = value.eventTax.appliedFeeTaxes?.map(tax => tax.translation.name + " - " + t("percentageFormat", { n: tax.amount / 100 }) + " ");

        if (!value || !value.eventTax.appliedFeeTaxes || value.eventTax.appliedFeeTaxes.length <=0)
            return "";
        
        return (
            <Tooltip title={title} placement="bottom" trigger="hover">
                <div className={styles.icon}>
                    <MdCheckCircle className={styles.iconCheckCirclColor} />
                </div>
            </Tooltip>
        );
    }

}

export default withTranslation(["event"])(EventFeeTaxedRenderer);