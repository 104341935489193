// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import { Col, DatePicker, Row } from "antd";
import { cloneDeep } from "lodash";
import { Dayjs } from "translations";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ComparisonType } from "web-service";
import { filterConstants, FilterInputProps, FilterValueDate } from "../data";
import styles from "../styles/filter-input-value.module.less";
import FilterListInputLabel from "./filter-list-input-label";
import Icon from "@ant-design/icons";
import { CalendarIcon } from "../../icons";

const { RangePicker } = DatePicker;

interface Props extends FilterInputProps<Dayjs>, WithTranslation {
    table?: boolean;
}

interface State {
    visible: boolean;
}

/**
 * Class for rendering dates
 * */
class FilterListDateSingle extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { values, showVisibilityToggle } = props;

        this.state = {
            visible: (showVisibilityToggle === undefined || showVisibilityToggle === false || (showVisibilityToggle && values !== undefined && values.length > 0))
        };
    }

    /**
     * Process input on change value
     * @param date
     * @param date2
     * @param index
     */
    handleChange = (date: Dayjs | undefined, date2: Dayjs | undefined, index: number) => {
        const { filter, values, onChange, defaultComparisonType } = this.props;
        const value = new FilterValueDate();

        const comparison = values && values.length > 0 ? values[0].comparisonType : defaultComparisonType ?? ComparisonType.Equal;

        value.assign(
            "",
            date ? date.utc() : undefined,
            date2 ? date2.utc() : undefined,
            comparison
        );

        const newValues = cloneDeep(values ?? []);
        if (index >= 0)
            newValues[index] = value;

        if (onChange && filter)
            onChange(filter, newValues);
    };

    handleVisibilityChange = () => {
        const { filter, onChange, values } = this.props;
        const { visible } = this.state;

        const newVisible = !(visible || (values && values.length > 0));

        if (!newVisible && filter && onChange)
            onChange(filter, []);

        this.setState({ visible: newVisible });
    }

    render() {
        const { t, label, values, defaultComparisonType, table } = this.props;
        const { visible } = this.state;

        const trueValue = values ?? [];

        return (
            <Row align="middle">
                <FilterListInputLabel<Dayjs>
                    {...this.props}
                    open={visible || trueValue.length > 0}
                    onOpenChange={this.handleVisibilityChange}
                    options={filterConstants.dateOptions}
                    table={table}
                />
                {
                    (visible || trueValue.length > 0) &&
                    <Col span={24}>
                        {
                            (values && values.length > 0 ? values[0].comparisonType : defaultComparisonType ?? ComparisonType.Equal) === ComparisonType.Range ?
                                <RangePicker
                                    suffixIcon={<Icon component={CalendarIcon} />}
                                    className={styles.date}
                                    value={trueValue[0]?.value ? [trueValue[0]?.value, trueValue[0]?.value2 ?? trueValue[0]?.value] : null}
                                    onChange={(rangeValue) => this.handleChange(rangeValue?.[0] ?? undefined, rangeValue?.[1] ?? undefined, 0)}
                                    placeholder={[t("from"), t("to")]}
                                />
                                :
                                <DatePicker
                                    suffixIcon={<Icon component={CalendarIcon} />}
                                    className={styles.single}
                                    value={trueValue[0]?.value}
                                    onChange={(value) => this.handleChange(value ?? undefined, undefined, 0)}
                                    placeholder={label?.toString()}
                                />
                        }
                    </Col>
                }
            </Row>
        );
    }
}

export default withTranslation("ui")(FilterListDateSingle);