// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Col, Drawer, Dropdown, List, Row, Tooltip } from "antd";
import { cloneDeep } from "lodash";
import React, { Component, ReactElement } from "react";
import { i18next } from "translations";
import { Key } from "ts-key-enum";
import { elementToInputPropMap, FilterBaseProps, filterConstants, FilterInputProps, FilterValue, IDynamicObject, mapToObject, objectToMap } from "./data";
import FilterDrawerResults from "./filter-drawer-results";
import Icon from "@ant-design/icons";
import { IoChevronDown, IoClose } from "react-icons/io5";
import { FilterIcon } from "ui/icons";
import styles from "./styles/filter-drawer.module.less";
import { FaInfoCircle } from "react-icons/fa";

i18next.setDefaultNamespace("ui");

enum ActionType {
    Save,
    New
}

interface Props<T> {
    label: React.ReactNode;
    header?: ReactElement;
    value: T;
    defaultValue: T;    // Used for re-building the T value and preserving the methods that may be available within
    children: ReactElement[] | ReactElement;
    hideValues?: boolean;
    drawerHeader?: ReactElement[] | ReactElement;
    actionOptions: ActionType[];
    disabledActionNewLabel?: React.ReactNode;
    disabled?: boolean;
    loading?: boolean;

    onChange: (filters: T) => void;
    onActionSelect?: (filters: T, type?: ActionType) => void;
}

interface State<T> {
    keys: string[];
    pendingValue: T;
    valueMap: Map<string, FilterValue<any>[]>;
    oldValueMap: Map<string, FilterValue<any>[]>;
    propMap: Map<string, FilterInputProps<T>>;
    modalConfirmVisible: boolean;
    popupVisible: boolean;
    hasChanges: boolean;
}

/**
 * Component for viewing Filters as a drawer. 
 * All changes are not immediately applied.
 * */
class FilterDrawer<T extends IDynamicObject<K>, K> extends Component<Props<T>, State<T>> {

    constructor(props: Props<T>) {
        super(props);

        const { value, defaultValue, children } = props;

        const valueMap = objectToMap(value ?? defaultValue);

        this.state = {
            keys: Object.keys(value ?? defaultValue),
            valueMap,
            oldValueMap: cloneDeep(valueMap),
            propMap: this.getPropMap(children),
            pendingValue: cloneDeep(value),
            modalConfirmVisible: false,
            popupVisible: false,
            hasChanges: false
        };
    }

    componentDidUpdate = (prevProps: Props<T>) => {
        const { value, children } = this.props;

        if (value !== prevProps.value) {
            const valueMap = objectToMap(value);
            this.setState({
                pendingValue: cloneDeep(value),
                valueMap,
                oldValueMap: cloneDeep(valueMap),
                keys: Object.keys(value),
                hasChanges: false
            });

        }

        if (children !== prevProps.children) {
            this.setState({
                propMap: this.getPropMap(children)
            });
        }
    };

    getPropMap = (children: ReactElement[] | ReactElement) => {
        const propMap: Map<string, FilterInputProps<any>> = new Map();
        elementToInputPropMap(Array.isArray(children) ? children : [children]).forEach((c) => {
            if (c.bundleKeys) {
                const filterName = (c.filter ?? "").split(filterConstants.filterSeparator);
                filterName.forEach((name) => propMap.set(name, c));
            }
            else {
                propMap.set(c.filter ?? "", c);
            }
        });

        return propMap;
    };

    /**
     * Resets all filter values
     * */
    handleReset = (value: T) => {
        this.setState({
            pendingValue: cloneDeep(value),
            valueMap: new Map(),
            hasChanges: false
        }, () => {
            this.handleUpdatePendingValue();
            this.handleApplyChange();
        });
    };

    /**
     * Triggers the onChange
     * */
    handleApplyChange = () => {
        const { onChange } = this.props;
        const { pendingValue } = this.state;

        this.setState({
            modalConfirmVisible: false,
            popupVisible: false,
            hasChanges: false
        }, () => onChange(pendingValue));
    };

    /**
     * Handles addition
     * @param key
     */
    handleAdd = (key: string, defaultValue?: FilterValue<any>) => {
        let { valueMap, hasChanges } = this.state;

        const keyValues = valueMap.get(key) ?? [];
        const finalValue = cloneDeep(defaultValue) ?? new FilterValue();

        if (finalValue.value !== undefined && finalValue.value !== null)
            hasChanges = true;

        if (keyValues) {
            keyValues.push(finalValue);

            valueMap.set(key, keyValues);

            this.setState({
                valueMap,
                hasChanges
            }, this.handleUpdatePendingValue);
        }
    };

    /**
     * Updates Filter values
     * @param key
     * @param values
     */
    handleChange = (key: string, values: FilterValue<any>[]) => {
        const { valueMap } = this.state;

        valueMap.set(key, cloneDeep(values));

        this.setState({
            valueMap,
            hasChanges: true
        }, this.handleUpdatePendingValue);
    };

    /**
     * Deletes a filter
     * @param key
     * @param index
     */
    handleDelete = (key: string, index: number, doApply?: boolean) => {
        const { valueMap } = this.state;

        const keyValues = valueMap.get(key) ?? [];

        if (keyValues) {
            let changed = false;

            if (keyValues.length > index) {
                keyValues.splice(index, 1);
                changed = true;
            }
            else if (index === -1) {
                // Delete all
                valueMap.set(key, []);

                changed = true;
            }

            if (changed) {
                this.setState({
                    valueMap,
                    hasChanges: true
                }, () => {
                    this.handleUpdatePendingValue(() => {
                        if (doApply)
                            this.handleApplyChange();
                    });
                });
            }

        }
    };

    /**
     * Updates Pending Value
     * @param callback
     */
    handleUpdatePendingValue = (callback?: () => void) => {
        const { defaultValue } = this.props;
        const { valueMap } = this.state;
        this.setState({
            pendingValue: mapToObject<T, K>(cloneDeep(defaultValue), valueMap)
        }, callback);
    };

    /**
     * Handles Popover visibility change
     * @param popupVisible
     */
    handleNavigateChange = (popupVisible: boolean) => {
        const { hasChanges } = this.state;

        if (!popupVisible && hasChanges) {
            this.setState({ modalConfirmVisible: true });
        }
        else {
            this.setState({ popupVisible });
        }
    };

    handleFilterClose = (event: any) => {
        const { hasChanges } = this.state;

        if (event.key === Key.Escape && hasChanges) {
            this.setState({ modalConfirmVisible: true });
            return;
        }
        if (event.key === Key.Escape) {
            this.setState({ popupVisible: false });
        }
    };

    handleActionClick = (actionType: ActionType) => {
        const { onChange, onActionSelect } = this.props;
        const { pendingValue } = this.state;

        // Pass the changes up
        onChange(pendingValue);
        // Trigger an action
        onActionSelect?.(pendingValue, actionType);
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleFilterClose);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleFilterClose);
    }

    render() {
        const { label, children, value, defaultValue, header, drawerHeader, actionOptions, disabledActionNewLabel, disabled, loading } = this.props;
        const { valueMap, oldValueMap, propMap, keys, modalConfirmVisible, popupVisible } = this.state;

        return (
            <Row wrap={false} align="middle" gutter={8}>
                <Col>
                    <Button
                        icon={<FilterIcon />}
                        style={{ zIndex: popupVisible ? 999 : 1 }}
                        onClick={() => this.setState({ popupVisible: true })}
                        disabled={disabled}
                        loading={loading}
                    >
                        {label}
                    </Button>
                </Col>
                <Drawer
                    styles={{ body: { paddingBlock: 0 }, header: { borderBottom: "unset" }, footer: { padding: 16 } }}
                    placement="left"
                    open={popupVisible}
                    onClose={() => this.setState({ popupVisible: false })}
                    width={400}
                    destroyOnClose
                    closeIcon={<Icon component={IoClose} />}
                    title={label}
                    footer={
                        <Row align="middle" gutter={8} wrap={false}>
                            <Col flex="auto">
                                <Button style={{ padding: "0" }} size="small" type="link" onClick={() => this.handleReset(defaultValue)}>{i18next.t("ui:clearAll").toString()}</Button>
                            </Col>
                            <Col>
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                key: 1,
                                                onClick: () => this.handleActionClick(ActionType.New),
                                                disabled: !actionOptions.includes(ActionType.New),
                                                label: <span>
                                                    {i18next.t("ui:saveAsNew")}
                                                    {
                                                        !actionOptions.includes(ActionType.New) &&
                                                        <Tooltip title={disabledActionNewLabel}>
                                                            <Button
                                                                type="link"
                                                                shape="circle"
                                                                icon={<FaInfoCircle />}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </span>
                                            },
                                            ...(actionOptions.includes(ActionType.Save) ? [{
                                                key: 2,
                                                onClick: () => this.handleActionClick(ActionType.Save),
                                                label: i18next.t("ui:save")
                                            }] : [])
                                        ]
                                    }}
                                >
                                    <Button size="small">{i18next.t("save")}<Icon component={IoChevronDown} /></Button>
                                </Dropdown>
                            </Col>
                            <Col>
                                <Button size="small" type="primary" onClick={this.handleApplyChange}>{i18next.t("ui:apply").toString()}</Button>
                            </Col>
                        </Row>
                    }
                >
                    {drawerHeader}
                    <div style={{ width: "100%" }}>
                        <List
                            className={styles.drawerList}
                            itemLayout={"vertical"}
                        >
                            {children &&
                                (Array.isArray(children) ? children : [children])
                                    .map((c: ReactElement, ix) => {
                                        const props = c.props as FilterBaseProps<any>;
                                        let values: FilterValue<any>[] = [];

                                        if (props.filter) {
                                            if (props.filter.includes(filterConstants.filterSeparator)) {
                                                const allFilters = props.filter.split(filterConstants.filterSeparator);
                                                allFilters.forEach((f) => {
                                                    values.push(...(valueMap.get(f) ?? []));
                                                });
                                            }
                                            else {
                                                values = valueMap.get(props.filter) ?? [];
                                            }

                                            return <List.Item key={ix}>
                                                {
                                                    React.cloneElement(c, {
                                                        ...props,
                                                        values,
                                                        onAdd: this.handleAdd,
                                                        onChange: this.handleChange,
                                                        onRemove: this.handleDelete,
                                                        valueMap,
                                                        showTitle: true
                                                    })
                                                }
                                            </List.Item>;
                                        }

                                        return (
                                            React.cloneElement(c, {
                                                key: ix
                                            })
                                        );
                                    })
                            }
                        </List>
                    </div>
                </Drawer>
                <FilterDrawerResults
                    modalConfirmOpen={modalConfirmVisible}
                    values={
                        keys
                            .map(key => ({ key, values: (oldValueMap.get(key) ?? []) }))
                            .filter(k => k.values.length > 0)
                    }
                    propMap={propMap}
                    onReset={(type) => {
                        if (type === "all")
                            this.handleReset(defaultValue);
                        else
                            this.handleReset(value);
                    }}
                    onApplyChange={this.handleApplyChange}
                    header={header}
                />
            </Row>
        );
    }
}

export default FilterDrawer;
export { ActionType };