// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import Icon from "@ant-design/icons";
import { Checkbox, Col, Select, Typography } from "antd";
import { LabeledValue } from "antd/es/tree-select";
import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { ComparisonType } from "web-service";
import { FilterInputProps, FilterValue } from "../data";
import styles from "./styles/filter-list-input-label.module.less";
import clsx from "clsx";

const { Text } = Typography;

interface Props<T> extends FilterInputProps<T> {
    options: LabeledValue[];
    open: boolean;
    onOpenChange: () => void;
    table?: boolean;
}

class FilterListInputLabel<T> extends Component<Props<T>> {

    /**
     * Updates all values with the comparison
     * @param newValue
     */
    handleComparisonChange = (newValue: ComparisonType) => {
        const { filter, values, onChange, onAdd } = this.props;

        if (filter) {
            const newValues = cloneDeep(values ?? []);

            if (newValues.length === 0) {
                const newItem = new FilterValue<T>(undefined);
                newItem.setComparison(newValue);
                if (onAdd)
                    onAdd(filter, newItem);
            }
            else {
                newValues.forEach(v => v.setComparison(newValue));

                if (onChange)
                    onChange(filter, newValues);
            }
        }
    }

    render() {
        const { label, values, showVisibilityToggle, defaultComparisonType, open, onOpenChange, options, table } = this.props;

        return (
            <>
                <Col flex="auto">
                    {
                        showVisibilityToggle ?
                            <Checkbox
                                checked={open}
                                onChange={() => onOpenChange && onOpenChange()}
                            >
                                <Text>{label}</Text>
                            </Checkbox>
                            :
                            <Text className={styles.textColor} strong={table}>{label}</Text>
                    }
                </Col>
                <Col>
                    <Select
                        className={clsx([styles.select, { [styles.tableView]: table, [styles.visible]: (open || table) }])}
                        bordered={false}
                        suffixIcon={<Icon component={IoIosArrowDown} />}
                        size="middle"
                        style={{ width: "100%" }}
                        dropdownStyle={{ minWidth: 150 }}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        value={(values && values.length > 0 ? values[0].comparisonType : defaultComparisonType ?? ComparisonType.Equal)}
                        onChange={this.handleComparisonChange}
                        options={options}
                        disabled={!open}
                    />
                </Col>
            </>
        );
    }
}

export default FilterListInputLabel;