// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Col, DatePicker, Drawer, Form, FormInstance, Input, Row, Select } from "antd";
import { dayjs, Dayjs } from "translations";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SearchSelect } from "ui";
import { UserModel } from "user";
import { EventGetModel, serviceClient } from "web-service";
import { styleConstants } from "configuration";
import { getDemoLocations } from "../data/get-demo-locations";
import styles from "../styles/shared.less";

const { Option } = Select;
import { withApp, WithApp } from "state";

interface State {
    minStartDate: Dayjs;
    minEndDate: Dayjs;
    endDateOpen: boolean;    
    counter: number;
    loading: boolean;
}

interface Props extends WithTranslation, WithApp {
    setDemoEvent: (demoEvent: EventGetModel) => void;
    resetRenderReady: () => void;
    user: UserModel;
    open: boolean;
    onClose: () => void;
}

class DemoEventDrawer extends Component<Props, State> {
    formRef = React.createRef<FormInstance>();
    constructor(props: Props) {
        super(props);

        this.state = {
            minStartDate: dayjs.utc().startOf("day"),
            minEndDate: dayjs.utc().startOf("day").add(4, "days"),
            endDateOpen: false,
            counter: 0,
            loading: false
        };
    }

    disabledStartDate = (startValue: any) => {
        const adjusted = startValue.startOf('day');
        const date = dayjs.utc().add(startValue.utcOffset() * -1, "minutes").startOf('day');
        return adjusted < date;
    }

    onStartChange = (value: any) => {
        let endDate = dayjs.utc(value).add(4, "days");
        this.formRef.current?.setFieldsValue({
            demoEndDate: endDate,
            demoStartDate: value
        });
    }

    handleFinish = (values: any) => {
        const { setDemoEvent, t, app: { notification } } = this.props;
        this.setState({ loading: true });

        serviceClient.post("Demo", "Event", {
            name: values.demoEventName,
            selectedLocation: values.demoEventVenue,
            clientID: values.demoClient.value,
            selectedCurrency: values.demoCurrency.value,
            start: values.demoStartDate.format()
        })
            .promise
            .then((demoEvent: EventGetModel) => {
                this.setState({ loading: false });
                setDemoEvent(demoEvent);
            })
            .catch(() => {
                this.setState({ loading: false });
                notification.error({
                    message: t('shared:error'),
                    description: t('shared:genericError')
                });
            });
    }

    handleClose = () => {
        const { onClose } = this.props;
        const { loading} = this.state;

        if (!loading)
            onClose();       
    }

    render() {
        const { t, open, breakpoints } = this.props;
        const { loading } = this.state;

        return (
            <Drawer
                title={t("createDemoEvent")}
                footer={loading ? null : undefined}
                open={open}
                placement={breakpoints.lg ? "right" : "left"}
                width={breakpoints.lg ? 500 : "95%"}
                onClose={this.handleClose}
            >
                <p>{t("demoEventDescription")}</p>
                <Form
                    scrollToFirstError={true}
                    onFinish={this.handleFinish}
                    layout="vertical"
                    ref={this.formRef}
                >
                    <Row className={styles.formGutter}>
                        <Col xs={24}>
                            <Form.Item
                                name="demoEventName"
                                label={t("demoEventName")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("shared:validationRequired", { field: t("demoEventName") })
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="demoEventVenue"
                                label={t("demoEventVenue")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("shared:validationRequired", { field: t("demoEventVenue") })
                                    },
                                ]}
                            >
                                <Select disabled={loading}>
                                    { Object.entries(getDemoLocations(t)).map(city =>
                                            <Option value={city[1].key} key={city[1].key}>{city[1].value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="demoClient"
                                label={t("client")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("shared:validationRequired", { field: t("client") })
                                    },
                                ]}
                            >
                                <SearchSelect
                                    disabled={loading}
                                    action="Select"
                                    controller="Client"
                                    showSearch={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="demoCurrency"
                                label={t("currency")}
                                shouldUpdate={(prevValues, curValues) => prevValues !== curValues}
                                rules={[
                                    {
                                        required: true,
                                        message: t("shared:validationRequired", { field: t("currency") })
                                    },
                                ]}
                            >
                                <SearchSelect
                                    disabled={loading}
                                    action="Select"
                                    controller="Currency"
                                    getData={() => {
                                        return { clientID: this.props.user.clientID }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item
                                name="demoStartDate"
                                label={t("demoStartDate")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("shared:validationRequired", { field: t("demoStartDate") })
                                    },
                                ]}
                            >
                                <DatePicker
                                    disabled={loading}
                                    disabledDate={this.disabledStartDate}
                                    onChange={this.onStartChange}
                                    showNow={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} >
                            <Form.Item
                                name="demoEndDate"
                                label={t("demoEndDate")}
                            >
                                <DatePicker
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {t("create")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        );
    }
}

export default withApp(withTranslation(["event", "shared"])(DemoEventDrawer));