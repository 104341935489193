// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from "react";
import { ComparisonType } from "web-service";
import { SearchSelect, SearchSelectItem } from "../../search-select";
import { filterConstants, FilterInputProps, FilterValue } from "../data";
import FilterListInputLabel from "./filter-list-input-label";
import styles from "./styles/filter-list-search-select.module.less";
import Icon from "@ant-design/icons";
import { IoClose } from "react-icons/io5";
import { Col, Row, Tag } from "antd";

interface Props<T> extends FilterInputProps<string> {
    mode?: 'multiple' | 'tags'
    placeholder?: React.ReactNode;
    // Search Select Props
    controller?: string;
    action?: string;
    getData?: (valueMap: Map<string, FilterValue<any>[]> | undefined) => Partial<T>;
    table?: boolean;
}

interface State {
    visible: boolean;
}

class FilterListSelect<T> extends Component<Props<T>, State> {
    constructor(props: Props<T>) {
        super(props);

        const { values, showVisibilityToggle } = props;

        this.state = {
            visible: (showVisibilityToggle === undefined || showVisibilityToggle === false || (showVisibilityToggle && values !== undefined && values.length > 0))
        };
    }

    /**
     * Process input on change value
     * @param e Input value
     */
    handleChange = (e: SearchSelectItem | SearchSelectItem[]) => {
        const { filter, onChange, values } = this.props;

        const finalValues: FilterValue<string>[] = [];
        const comparisonType = values && values.length > 0 ? values[0].comparisonType : ComparisonType.Equal;

        (Array.isArray(e) ? e : [e])
            .map((o) => {
                if (o) {
                    const newValue = new FilterValue<string>();
                    newValue.assign(o.name, o.value, undefined, comparisonType);
                    finalValues.push(newValue);
                }
            });

        if (onChange && filter)
            onChange(filter, finalValues);
    };

    handleVisibilityChange = () => {
        const { visible } = this.state;

        const newVisible = !visible;

        if (!newVisible)
            this.handleChange([]);

        this.setState({ visible: newVisible });
    }

    render() {
        const { values, mode, disabled, placeholder, getData, action, controller, table, valueMap } = this.props;
        const { visible } = this.state;

        return (
            <Row align="middle">
                <FilterListInputLabel<string>
                    {...this.props}
                    open={visible || (values !== undefined && values.length > 0)}
                    onOpenChange={this.handleVisibilityChange}
                    options={filterConstants.selectOptions}
                    table={table}
                />
                {
                    (visible || (values !== undefined && values.length > 0)) &&
                    <Col span={24}>
                        <SearchSelect
                            action={action}
                            controller={controller}
                            showSearch
                            getData={getData ? () => getData(valueMap) : undefined}
                            onChange={this.handleChange}
                            mode={mode}
                            disabled={disabled}
                            style={{ width: "100%" }}
                            placeholder={placeholder}
                            value={values?.map(v => ({ name: v.label?.toString() ?? "", value: v.value ?? "", disabled: false }))}
                            tagRender={(props) => <Tag className={styles.tag} onClose={props.onClose} closeIcon={<Icon className={styles.tagClose} component={IoClose} />} closable>{props.label}</Tag>}
                        />
                    </Col>
                }
            </Row>
        );
    }
}

export default FilterListSelect;