// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { ICellEditorParams } from 'ag-grid-community';
import { DatePicker } from 'antd';
import { dayjs, Dayjs } from 'translations';
import React, { Component } from 'react';
import styles from './styles/date-picker-editor.less';
import { PickerRef } from "rc-picker/es/interface";

interface Props extends ICellEditorParams {
    required?: boolean | undefined;
    disabledDate?: ((currentDate: Dayjs | undefined) => boolean) | undefined;
    getDefault?: (() => Dayjs | undefined) | undefined;
}

interface State {
    value: Dayjs | undefined;
    required: boolean;
    open: boolean;
}

class DatePickerEditor extends Component<Props, State> {
    datePickerRef = React.createRef<PickerRef>();

    constructor(props: Props) {
        super(props);
        
        this.state = {
            open: false,
            value: props.value === null ? (props.getDefault === undefined ? undefined : props.getDefault()) : dayjs.utc(props.value),
            required: this.props.required === undefined || this.props.required
        };

        setTimeout(() => this.setState({ open: true }), 10);
    }

    getValue() {
        //Forces the datepicker to commit outstanding changes.
        this.datePickerRef.current?.blur();
        return this.state.value?.format();
    }

    isPopup() {
        return true;
    }

    handleChange = (date: Dayjs | null) => {
        this.setState({ value: date === null ? undefined : date }, () => this.props.stopEditing());
    }

    afterGuiAttached() {
        const { column } = this.props;
        this.datePickerRef.current?.focus();
        this.datePickerRef.current?.nativeElement?.style.setProperty("width", `${column.getActualWidth()}px`);
    }

    isCancelAfterEnd() {
        return (this.state.required && this.state.value === undefined)
    }

    render() {
        const { open, value } = this.state;
        const { disabledDate } = this.props;

        return (
            <DatePicker
                open={open}
                onOpenChange={(e) => this.setState({open: e})}
                defaultOpen={true}
                defaultValue={value}
                disabledDate={disabledDate}
                format="l"
                ref={this.datePickerRef}
                onChange={this.handleChange}
                className={styles.date}
                rootClassName="ag-custom-component-popup ag-theme-balham ag-cell-inline-editing"
                showNow={false}
            />
        );
    }
}

export default DatePickerEditor;
