// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
class FlywheelWrapper {
    isInitialized = false;

    init(writeKey, user) {
        if (this.isInitialized)
            return;

        (function () {
            window.flywheel = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"].reduce((function(e, n) {
                return e[n] = function() {
                    for (var e, i, r = [], t = 0; t < arguments.length; t++) r[t] = arguments[t];
                    if (window.flywheel.initialized) return (e = window.flywheel)[n].apply(e, r);
                    (i = window.flywheel.preLibInitBuffer)[n] || (i[n] = []), window.flywheel.preLibInitBuffer[n].push(r)
                }, e
            }), {
                writeKey: writeKey,
                apiHost: "theflywheel.app",
                snippetVersion: "0.0.1",
                preLibInitBuffer: {},
                runInIframe: ""
            });
            var e = document.createElement("script");
            e.type = "text/javascript", e.async = !0, e.src = "https://cdn.theflywheel.app/flywheel.min.js";
            var n = document.getElementsByTagName("script")[0];
            n.parentNode && n.parentNode.insertBefore(e, n), window.flywheel.runInIframe || window.flywheel.page();
            e.onload = () => {
                if (user) {
                    window.flywheel.identify(
                        user.id,
                        {
                            userId: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            username: user.email,
                        },
                        {
                            context: {
                                groupId: user.clientId,
                            }
                        }
                    );
                }
            };
        })();

        this.isInitialized = true;
    }
}

export default new FlywheelWrapper();
