// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from 'react';
import { Col, Row, List } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';

import { permissionLookup } from 'security';

import styles from './styles/manage-pane.less'
import { UserModel } from 'user';

interface Props extends WithTranslation {
    user: UserModel;
    showFeedback: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface State {

}

class UserPane extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { t, user, showFeedback } = this.props;

        return (
            <Row className={styles.menuListContainer}>
                <Col span={24}>
                    <div className={styles.menuList}>
                        <List>
                            <List.Item className={styles.menuListItem} key={0} onClick={showFeedback}>
                                <a>
                                    {t('supportFeedBack')}
                                </a>
                            </List.Item>
                            <List.Item className={styles.menuListItem} key={1}>
                                <a href={user.baseUrl + '/Manage/MyAccount'}>
                                    {t('account')}
                                </a>
                            </List.Item>
                            {
                                user.clientPermissions.includes(permissionLookup.USER_MANAGEMENT) ?
                                    (
                                        <List.Item className={styles.menuListItem} key={2}>
                                            <a href={user.baseUrl + '/Manage'}>
                                                {t('userManagement')}
                                            </a>
                                        </List.Item>
                                    )
                                    : ""
                            }
                            <List.Item className={styles.menuListItem} key={3}>
                                <a href={'/Client/Report/Display/Contract'}>
                                    {t('contract')}
                                </a>
                            </List.Item>
                            <List.Item className={styles.menuListItem} key={4}>
                                <a href={user.baseUrl + '/Account/LogOff'}>
                                    {t('logOut')}
                                </a>
                            </List.Item>
                        </List>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default withTranslation('menu')(UserPane);