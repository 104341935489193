// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Col, Row } from "antd";
import React, { Component, ReactElement } from "react";
import CollapseHeight from "../../collapse-height";
import { FilterBaseProps } from "../data/filter-input-props";
import Icon from "@ant-design/icons";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";

interface Props extends FilterBaseProps<any> {
    extra?: React.ReactNode;
    hideCollapse?: boolean;
    hide?: boolean;
}

interface State {
    visible: boolean;
}

/**
 * Container for bundling other filters
 * */
class FilterListCollapseContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { valueMap } = props;



        this.state = {
            visible: (valueMap !== undefined && valueMap.size > 0)
        };
    }

    render() {
        const { label, valueMap, children, extra, onAdd, onChange, onRemove, hideCollapse, hide } = this.props;
        const { visible } = this.state;

        return <>
            {
                !hide &&
                <>
                    {
                        hideCollapse ?
                            <>
                                {label}
                                {

                                    (children && valueMap) ?
                                        (Array.isArray(children) ? children : [children])
                                            .map((c: ReactElement, ix) => {
                                                const props = c.props as FilterBaseProps<any>;
                                                const values = valueMap.get(props.filter ?? "") ?? [];
                                                return (
                                                    React.cloneElement(c, {
                                                        values,
                                                        onAdd,
                                                        onChange,
                                                        onRemove,
                                                        ...props,
                                                        valueMap,
                                                        showTitle: true,
                                                        key: ix
                                                    })
                                                );
                                            })
                                        : ""
                                }
                            </>
                            :
                            <CollapseHeight
                                expand={visible}
                                title={
                                    <Button block style={{ padding: 0, textAlign: "unset" }} type="text" icon={<Icon component={visible ? IoChevronDown : IoChevronForward} />} onClick={() => this.setState({ visible: !visible })}>
                                        {label}
                                    </Button>
                                }
                            >
                                <Row gutter={[0, 8]}>
                                    {
                                        (children && valueMap) ?
                                            (Array.isArray(children) ? children : [children])
                                                .map((c: ReactElement, ix) => {
                                                    const props = c.props as FilterBaseProps<any>;
                                                    const values = valueMap.get(props.filter ?? "") ?? [];
                                                    return (
                                                        <Col key={ix} span={24} style={{ minHeight: 34 }}>
                                                            {React.cloneElement(c, {
                                                                values,
                                                                onAdd,
                                                                onChange,
                                                                onRemove,
                                                                ...props,
                                                                valueMap,
                                                                showTitle: true,
                                                                key: ix
                                                            })}
                                                        </Col>
                                                    );
                                                })
                                            : ""
                                    }
                                </Row>
                            </CollapseHeight>
                    }
                    {extra}
                </>
            }
        </>;
    }
}

export default FilterListCollapseContainer;