// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, List, Row } from 'antd';
import { serviceClient } from 'web-service';
import { UserModel } from '../../user';
import SearchItem from '../data/search-item';
import styles from './styles/manage-pane.less';

interface Props extends WithTranslation {
    user: UserModel;
    categories: boolean;
}

interface State {
    reports: Array<SearchItem>
}

class ReportPane extends Component<Props, State> {
    constructor(props : Props) {
        super(props);

        this.state = {
            reports: []
        };

        serviceClient.post('Report', 'Search', {
            eventID: props.user.event == null ? '' : props.user.event.id
        }).promise.then((result) => {
            this.setState({ reports: result.items });
        });
    }

    layoutItems = (array: Array<SearchItem>, user: UserModel) => {
        let result : any = {};

        for (let i = 0; i < array.length; i++) {
            var category = array[i].description;

            if (result[category] == undefined) {
                result[category] = [];
            }

            result[category].push(<List.Item className={styles.menuListItem} key={i}><a href={user.baseUrl + "/Report/Display/" + array[i].id}>{array[i].name}</a></List.Item>)
        }

        return Object.keys(result).map(function (key, x) {
            return (<Col span={24} md={8} key={x}>
                <div className={styles.menuList}>
                    <h3 className={styles.menuListTitle}>{key}</h3>        
                    <List>
                        {result[key]}
                    </List>
                </div>
            </Col>);
        });
    }

    layoutSimpleItems = (array : Array<SearchItem>, user : UserModel) => {
        let result = [];

        for (let i = 0; i < array.length; i++) {
            result.push(<List.Item className={styles.menuListItem} key={i}><a href={user.baseUrl + "/Report/Display/" + array[i].id}>{array[i].name}</a></List.Item>)
        }

        return (
            <Col span={24}>
                <List>
                    {result.map(function (value, x) {
                        return value;
                    })}
                </List>
            </Col>
        );
    }

    render() {
        const { t, user, categories } = this.props;

        return (
            <Row className={styles.menuListContainer}>
                {categories ? this.layoutItems(this.state.reports, user) : this.layoutSimpleItems(this.state.reports, user)  }
            </Row>
        );
    }
}

export default withTranslation('menu')(ReportPane);