// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import React from "react";
import { Component, createRef, RefObject } from "react";
import { UserModel } from "user";
import { createScriptTag } from "utilities";

interface HelpScoutUser {
    name: string;
    email: string;
}

/** 
 *  Base route props.
 *  */
interface Props {
    user?: UserModel;
}

/** 
 *  State for managing route  
 * */
interface State {
    initialLoad: boolean;
}

class HelpScout extends Component<Props, State> {
    containerRef: RefObject<HTMLDivElement> = createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            initialLoad: true
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { user } = this.props;
        const { user: prevUser } = prevProps;

        if (user?.branding.beaconId !== prevUser?.branding.beaconId) {
            if (user?.branding.beaconId && user.isLoggedIn)
                this.helpScoutInit(user);
            else
                this.helpScoutDestroy();
        }
    }
    
    helpScoutInit = (user: UserModel) => {
        this.setState({ initialLoad: false });
        let script = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;

        script += `window.Beacon('init', '` + user.branding.beaconId + `');`;
        script += `window.Beacon('identify', ` + JSON.stringify({
            name: user.firstName + ` ` + user.lastName,
            email: user.email
        } as HelpScoutUser) + `);`;

        this.containerRef.current?.appendChild(createScriptTag("text/javascript", script));
    }

    helpScoutDestroy = () => {
        if (!this.state.initialLoad) {
            // Flag this back to true again
            this.setState({ initialLoad: true });

            var scriptTag = createScriptTag("text/javascript", "Beacon('destroy')");
            this.containerRef.current?.appendChild(scriptTag);
        }
    }

    render() {
        return (<div style={{ display: "none" }} ref={this.containerRef}></div>);
    }
}

export default HelpScout;