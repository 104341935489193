// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component, ReactNode } from 'react';
import styles from './styles/title.less';

interface Props {
    className?: string;
    title?: string;
    action?: ReactNode;
}

class Title extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { className, title, action } = this.props;

        return (
            <div className={(className || '') + " " + styles.titleContainer}>
                {title ? (<h1 className={styles.title}>{title}</h1>) : ''}
                {action ? (<div className={styles.action}>{action}</div>) : ''}
            </div>
        );
    }
}

export default Title