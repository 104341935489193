// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
interface SearchItem {
    type: SearchType;
    name: string;
    detail: string;
    description: string;
    description2: string | null;
    description3: string | null;
    number: string;
    id: string;
    category: string;
    favorite: boolean;
}

enum SearchType {
    All = 0,
    Events = 1,
    Reservations = 2,
    Reports = 3,
    Pages = 4
}

export default SearchItem;
export { SearchType };
