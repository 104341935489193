// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Col, Input, List, Row, Typography } from "antd";
import { cloneDeep, debounce } from "lodash";
import React, { useCallback, useState } from "react";
import { DragDropContext, Draggable, DragUpdate, Droppable, DropResult } from "react-beautiful-dnd";
import { withTranslation, WithTranslation } from "react-i18next";
import { IoMdSearch } from "react-icons/io";
import { IoAdd, IoRemove } from "react-icons/io5";
import InputLabel from "../input-label/input-label";
import ColumnEditorItem from "./column-editor-item";
import { ExtendedValue } from "./interface/extended-value";
import styles from "./styles/column-editor.module.less";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const { Title } = Typography;
const ANIMATION_DELAY = 500;

interface Props extends WithTranslation {
    id?: string;
    showSearch?: boolean;
    sortable?: boolean;
    disabled?: boolean;
    title?: React.ReactNode;
    value?: string[];
    options: ExtendedValue[];
    onChange?: (value: string[]) => void;
}

const ColumnEditor: React.FC<Props> = ({
    t, id, showSearch, sortable, disabled, title, value, options, onChange
}: Props) => {

    const [availableListRef, _] = useAutoAnimate();
    const [addedListRef, setEnableAddedListRefAnimations] = useAutoAnimate();

    const [dragging, setDragging] = useState<boolean>(false);
    const [dragError, setDragError] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");

    const currentValue = value?.filter(x => options.some(r => r.key === x)) ?? [];
    const filteredValue = (
        !sortable ?
            options.filter(r => currentValue.includes(r.value.toString()))
            : currentValue.map(c => options.find(o => o.value === c) as ExtendedValue).filter(c => c !== undefined))
        .filter(r => (r!.label ?? "").toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
    const filteredOptions = options.filter(r => !currentValue.includes(r.value.toString()) && (r.label ?? "").toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);

    /**
     * Updates registration being selected
     * @param newValue
     * @param isAdd
     */
    const handleToggleValue = (newValue: string, isAdd: boolean) => {
        let pendingValue = cloneDeep(value ?? []);

        if (isAdd)
            pendingValue.push(newValue);
        else
            pendingValue = pendingValue.filter(p => p !== newValue);

        if (onChange)
            onChange(pendingValue);
    };

    /**
     * Triggers sorting when drag ends
     * @param event
     */
    const handleSort = (event: DropResult) => {
        const { source, destination } = event;

        if (dragging) {
            setDragError(false);
            debounceEnableAnimation();
        }

        if (sortable && value && destination) {
            // if the item is dropped on an item that's locked, don't do anything
            if (options.find(o => o.value === value[destination.index])?.locked)
                return;

            const result = Array.from(value);

            const [removed] = result.splice(source.index, 1);
            result.splice(destination.index, 0, removed);

            if (onChange)
                onChange(result);
        }
    };

    /**
     * Check if droppable when dragging
     * @param e
     */
    const handleDragUpdate = (e: DragUpdate) => {
        if (e.destination?.index && filteredValue.length > e.destination.index) {
            const destinationItem = filteredValue[e.destination.index];
            setDragError(!!destinationItem.locked);
        }
    }

    const handleOnDragStart = () => {
        if (!dragging) {
            setEnableAddedListRefAnimations(false);
            setDragging(true);
        } else {
            // Cancel previous
            debounceEnableAnimation.cancel();
        }
    }

    /**
     * Re-enable animation when drag ends
     */
    const debounceEnableAnimation = useCallback(debounce(() => {
        setEnableAddedListRefAnimations(true);
        setDragging(false);
    }, ANIMATION_DELAY), [dragging]);

    return (
        <div>
            {title && <Title level={4} className={styles.title}>{title}</Title>}
            {
                showSearch &&
                <Input
                    allowClear
                    disabled={disabled}
                    size="large"
                    prefix={<IoMdSearch />}
                    placeholder={t("search")}
                    type="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target?.value)}
                />
            }

            <Row className={styles.columnDragList}>
                <Col xs={24} md={12}>
                    <Row justify="space-between" align="middle" className={styles.marginBottom}>
                        <InputLabel className={styles.label}>
                            {
                                !searchValue ?
                                    t("nAvailable", { n: options.length - currentValue.length })
                                    :
                                    t("nAvailablemFiltered", { n: options.length - currentValue.length, m: filteredOptions.length })
                            }
                        </InputLabel>
                        <Button
                            type="link"
                            className={styles.link}
                            size="small"
                            disabled={!filteredOptions.length}
                            onClick={() => {
                                if (onChange)
                                    onChange([...currentValue, ...filteredOptions.map(o => o.value.toString())]);
                            }}
                        >
                            {
                                !searchValue || filteredOptions.length === 0 ?
                                    t("addAll")
                                    :
                                    t("addNFiltered", { n: filteredOptions.length })
                            }
                        </Button>
                    </Row>
                    <List className={styles.scroll} split={false}>
                        <div ref={availableListRef}>
                            {
                                filteredOptions.map(r =>
                                    <ColumnEditorItem
                                        key={r.value}
                                        value={r}
                                        icon={
                                            <Button
                                                onClick={() => handleToggleValue(r.value.toString(), true)}
                                                icon={<IoAdd />}
                                                type="link"
                                                aria-label={t("add")}
                                            />
                                        }
                                        searchValue={searchValue}
                                    />
                                )
                            }
                        </div>
                    </List>
                </Col>
                <Col xs={24} md={12}>
                    <Row justify="space-between" align="middle" className={styles.marginBottom}>
                        <InputLabel className={styles.label}>
                            {
                                !searchValue ?
                                    t("nAdded", { n: currentValue.length })
                                    :
                                    t("nAddedmFiltered", { n: currentValue.length, m: filteredValue.length })
                            }
                        </InputLabel>
                        <Button
                            type="link"
                            className={styles.link}
                            size="small"
                            disabled={!filteredValue.filter(f => !f.locked).length}
                            onClick={() => {
                                if (onChange) {
                                    const finalOptions = options.filter(o => currentValue.includes(o.value.toString()) && (o.locked || !filteredValue.some(f => f.value === o.value)))
                                    onChange(finalOptions.map(o => o.value.toString()));
                                }
                            }}
                        >
                            {
                                !searchValue || !filteredValue.filter(f => !f.locked).length ?
                                    t("removeAll")
                                    :
                                    t("removeNFiltered", { n: filteredValue.filter(f => !f.locked).length } )
                            }
                        </Button>
                    </Row>

                    <div className={styles.scrollAndDrag}>
                        <DragDropContext
                            onDragEnd={e => handleSort(e)}
                            onDragStart={handleOnDragStart}
                            onDragUpdate={e => handleDragUpdate(e)}
                        >
                            <List split={false}>
                                <Droppable droppableId={id ?? "column"}>
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={dragError ? { cursor: "not-allowed" } : undefined}
                                        >
                                            <div ref={addedListRef}>
                                                {
                                                    filteredValue.map((r, ix) =>
                                                        <Draggable
                                                            key={r.value}
                                                            draggableId={r.value.toString()}
                                                            index={ix}
                                                            isDragDisabled={r.locked || !sortable || !!searchValue}
                                                        >
                                                            {(provided, snapshot) =>
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <ColumnEditorItem
                                                                        sortable={!(r.locked && !sortable)}
                                                                        value={r}
                                                                        dragHandleProps={provided.dragHandleProps || undefined}
                                                                        icon={
                                                                            <Button
                                                                                onClick={() => handleToggleValue(r.value.toString(), false)}
                                                                                icon={<IoRemove />}
                                                                                type="link"
                                                                                aria-label={t("remove")}
                                                                            />
                                                                        }
                                                                        searchValue={searchValue}
                                                                        hasError={snapshot.isDragging && dragError}
                                                                    />
                                                                </div>
                                                            }
                                                        </Draggable>
                                                    )
                                                }
                                            </div>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </List>
                        </DragDropContext>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation(["ui"])(ColumnEditor);