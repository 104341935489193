// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Col, Radio, RadioChangeEvent, Row, Tag } from 'antd';
import { TFunction } from 'i18next';
import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { withTranslation, WithTranslation } from 'react-i18next';
import { permissionLookup } from 'security';
import { UserModel } from 'user';
import { EventListLinksModel, serviceClient } from 'web-service';
import styles from './styles/link-pane.less';



interface Props extends WithTranslation {
    user: UserModel;
}

interface State {
    links?: EventListLinksModel;
    displayLanguage: number;
    copiedIndex: number;
};

class LinkPane extends Component<Props, State> {
    

    constructor(props : Props) {
        super(props);
       
        this.state = {
            copiedIndex: -99,
            displayLanguage: -1
        };
     
        serviceClient.post('ListLinks', 'Event', {
            eventID: props.user.event != null ? props.user.event.id: null
        }).promise.then((result: EventListLinksModel) => {
            this.setState({ links: result  });
        });        
    }

    handleCopy = (i : number) => {
        this.setState({ copiedIndex: i });
    }

    layoutRow = (i: number, tag: string, name: string, link: string, t: TFunction) => {      

        return (
            <Row style={{ height: "100%" }} align="middle" justify="space-between" className={styles.rowResults + " " + styles.rowResultsLinks} key={i}>
                <Col className={styles.colResultsTag}>
                    <Tag className={styles.tag + " " + (styles as any)[tag + 'Tag']}>{t(tag)}</Tag>
                </Col>
                <Col className={styles.colResultsMain + " " + styles.colResultsContentLinks}>
                    <span className={styles.contentTitle}>{name}</span>
                    <Row align="middle" justify="start" className={styles.rowLinks}>
                        <Col>
                            <CopyToClipboard text={link}>
                                <Button size='small' type='default' className={styles.buttonCopy + (i == this.state.copiedIndex ? " " + styles.buttonCopyCopied : "")} onClick={()=>this.handleCopy(i)}>{ (i == this.state.copiedIndex) ? t('copied') : t('copy') }</Button>
                            </CopyToClipboard>
                        </Col>
                        <Col>
                            <a href={"//" + link} target="new">{link}</a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    addLanguage = (index: number) => {
        if (index === -1)
            return '';

        const { links } = this.state;

        var language = links?.secondaryLanguages[index];

        return "?culture=" + language?.value;
    }

    handleLanguageChange = (e: RadioChangeEvent) => {
        this.setState({ displayLanguage: e.target.value });
    }

    render() {
        const { user, t } = this.props;
        const { links, displayLanguage } = this.state;

        return (
            <div>
                <Row className={styles.linksHeaderRow + " " + styles.popoverHeader}>
                    <Col className={styles.menuListTitle}>
                            {t("eventSiteLinks")}
                    </Col>
                    {
                        links && links.secondaryLanguages.length > 0 && 
                        <Col className={styles.linksLangs}>                            
                            <Radio.Group size="small" value={displayLanguage}>
                                <Radio.Button key={-1} value={-1} onChange={this.handleLanguageChange}>{links.defaultLanguage.name}</Radio.Button>
                                {links.secondaryLanguages.map((language, i) => <Radio.Button key={i} value={i} onChange={this.handleLanguageChange}>{language.name}</Radio.Button>)}
                            </Radio.Group>                            
                        </Col>
                    }
                </Row>
                {
                    links && <div className={styles.popoverLinks}>
                        {this.layoutRow(-1, 'event', t('reservationSiteLink'), links.base + "/" + links.link + this.addLanguage(displayLanguage), t)}
                        {links.items.map((item, i) =>
                            this.layoutRow(i, item.public ? 'public' : 'private', item.name, links.base + "/" + links.link + "/" + item.link + this.addLanguage(displayLanguage), t)
                        )}
                    </div>                  
                }
                {
                    user.event != null && user.event.eventPermissions.includes(permissionLookup.EVENT_MANAGEMENT) ? (
                        <div className={styles.popoverFooter}>
                            <Button type="primary" href={user.baseUrl + '/Wizard/Index/Links'}>{t("editSiteLinks")}</Button>
                        </div>
                    ) : ""
                }
            </div>
        );
    }
}

export default withTranslation('menu')(LinkPane);