// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Col, Collapse, Divider, Row, Skeleton, Typography } from 'antd';
import { dayjs } from "translations";
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserModel } from 'user';
import { serviceClient } from 'web-service';
import { BookingGroupDetailsItemModel } from '../../booking/group-details-model';
import BookingNightGrid from './booking-night-grid';
import styles from './styles/search-pane.less';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import fluentPeopleIcon from "../images/fluent-people-icon.svg";
import clsx from "clsx";

interface Props extends WithTranslation {
    user: UserModel;
    bookingGroupID: string;
    hasAbandonedRooms: boolean;
}

interface State {
    bookingDetails: Array<BookingGroupDetailsItemModel>;
    loading: boolean;
    active: boolean;
}

class BookingDetailsPane extends Component<Props, State> {
    constructor(props : Props) {
        super(props);
        this.state = {
            bookingDetails: [],
            loading: true,
            active: false
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.bookingGroupID !== this.props.bookingGroupID || prevProps.hasAbandonedRooms != this.props.hasAbandonedRooms) {
            if (this.state.active)
                this.loadBookingDetails();
            else
                this.setState({bookingDetails:[]});
        }
    }

    handleCollapseChange = (key: string | string[]) => {
        if (key == "1") {
            if (this.state.bookingDetails.length < 1)
                this.loadBookingDetails();

            this.setState({
                active: true
            });
        }
        else {
            this.setState({
                active: false
            });
        }
    }

    loadBookingDetails() {
        const { bookingGroupID, hasAbandonedRooms } = this.props;

        serviceClient.post("ListBookingDetails", "Booking", { bookingGroupID, hasAbandonedRooms })
            .promise
            .then((result: { items: Array<BookingGroupDetailsItemModel> }) => {
                this.setState({
                    bookingDetails: result.items,
                    loading: false 
                });
            });
    }

    render() {
        const { bookingDetails, loading } = this.state;
        const { t, user } = this.props;

        return (
            <Collapse
                bordered={false}
                expandIcon={
                    ({ isActive }) => <div className={styles.expandTable}>
                        <span>{t("Details")}</span>
                        {isActive ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
                    </div>
                }
                expandIconPosition="end"
                onChange={this.handleCollapseChange}
                ghost
                items={[{
                    key: "1",
                    className: styles.header,
                    label: <span style={{ display: "none" }}>{t("Details")}</span>,
                    children: <>
                        {
                            loading
                                ? <div className={clsx(styles.rowResults, styles.reservationResults)}><Skeleton active /></div>
                                : bookingDetails.map((item, index) => {
                                    const checkin = dayjs.utc(item.checkIn, "YYYY-MM-DD");
                                    const checkout = dayjs.utc(item.checkOut, "YYYY-MM-DD");
                                    const dates = t("dateConcat", {
                                        startDate: t(checkin.year === checkout.year ? "dateShort" : "dateShortFull", { date: checkin }),
                                        endDate: t(checkin.month === checkout.month ? "dateShortDY" : "dateShortFull", { date: checkout })
                                    });

                                    return (
                                        <React.Fragment key={item.id}>
                                            { index > 0 && <Divider className={styles.expandDivider} style={{ marginBottom: 0 }} /> }
                                            <Row justify="space-between" className={clsx(styles.rowResults, styles.reservationResults)}>
                                                <Col flex="auto">
                                                    <Typography.Text strong style={{ display: "block" }}>
                                                        {item.hotelName}
                                                    </Typography.Text>
                                                    <Typography.Text className={styles.contentSmall}>{item.roomName}</Typography.Text>
                                                </Col>
                                                <Col>
                                                    <div className={clsx(styles.idTitle, styles.emphasisTitle)}>
                                                        {t("subBlock")}
                                                    </div>
                                                    <div className={styles.subblockName}>
                                                        <Typography.Text>{item.subBlockGroupName}</Typography.Text>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={12} align="middle" className={styles.rowGuests}>
                                                <Col>
                                                    <img src={fluentPeopleIcon} alt="" />
                                                </Col>
                                                {
                                                    item.guests.map((guest, index) =>
                                                        <Col key={index} className={styles.colGuestName}>
                                                            <a href={user.baseUrl + "/Booking/Group?BookingID=" + item.id}>{guest}</a>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                            {
                                                (item.days.length > 0) &&
                                                <div className={styles.reservationDateView}>
                                                    <Divider className={styles.expandDivider} />
                                                    <div className={styles.rowReservationDate}>
                                                        <Col className={styles.colDateInfo}>
                                                            <Typography.Text strong>{dates}</Typography.Text>
                                                            <div className={styles.emphasisTitle}>{t("checkInCheckOut")}</div>
                                                        </Col>
                                                        <Col className={styles.colDateCards}>
                                                            <BookingNightGrid days={item.days} />
                                                        </Col>
                                                    </div>
                                                </div>
                                            }
                                        </React.Fragment>
                                    )
                                })
                        }
                    </>
                }]}
            />
        )
    }
}

export default withTranslation('menu')(BookingDetailsPane);