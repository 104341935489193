// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
let permissionLookup = {
    RESERVATION_VIEW: "10",
    RESERVATION_EDIT: "20",
    REPORT_PLANNER: "30",
    REPORT_EXECUTIVE: "40",
    REPORT_SHAREABLE: "50",
    USER_MANAGEMENT: "60",
    EVENT_MANAGEMENT: "70",
    ROOMING_LIST_MANAGEMENT: "80",
    FEE_ADMINISTRATOR: "90"
};


export default permissionLookup;