import React from "react";
import clsx from "clsx";
import { WithTranslation, withTranslation } from "react-i18next";
import { Tooltip } from "antd";
import styles from "./styles/block-icon-input-label.module.less";
import { ContractIcon, GdsIcon } from "../icons";

type LabelType = "form" | "floatStart" | "floatEnd" | "iconLeft";

interface Props extends WithTranslation {
    contract?: boolean;
    gds?: boolean;
    visible?: boolean;
    type?: LabelType;
    style?: React.CSSProperties;
}

const BlockIconInputLabel: React.FC<Props> = ({ t, type = "form", contract, gds, visible, style }: Props) => {
    return (
        <div
            className={clsx(styles.label, "block-label-icon", {
                [styles.visible]: !!visible,
                [styles.hidden]: visible === false,
                [styles.floatStart]: type === "floatStart",
                [styles.floatEnd]: type === "floatEnd"
            })}
            style={style}
        >
            { contract && <Tooltip mouseLeaveDelay={0} title={t("contractIconTooltip")}><ContractIcon className={styles.contractIcon} />&nbsp;</Tooltip> }
            { gds && <Tooltip mouseLeaveDelay={0} title={t("gdsIconTooltip")}><GdsIcon className={styles.gdsIcon} />&nbsp;</Tooltip> }
        </div>
    )
}

export default withTranslation(["ui"])(BlockIconInputLabel);
