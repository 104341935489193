// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Currency, EventAdvertisementModel } from "../event";

interface EventHotelOrderModel extends EventAdvertisementModel {
    id?: string;
    hotelId?: string;
    hotelOrderType: HotelOrderType;
    headquarters: boolean | undefined;
    averageRateNight: number | undefined;
    hotelLogo: number | undefined;
    order: number;
    currency: Currency | undefined;
}

enum HotelOrderType {
    Hotel = 0,
    Advertisement = 1
}

export { HotelOrderType };
export type { EventHotelOrderModel };