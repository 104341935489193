// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { BookingDay } from "web-service";
import BookingNightGridItem from "./booking-night-grid-item";
import styles from "./styles/booking-night-grid.less";
import { ComponentCarousel } from "ui";

interface Props extends WithTranslation {
    days: BookingDay[];
}

class BookingNightGrid extends Component<Props> {
    render() {
        const { days } = this.props;
        
        return (
            <ComponentCarousel
                className={styles.dateCarousel}
                slidesToShow={6}
                slidesToScroll={5}
                infinite={false}
                dots={false}
                responsive={[
                    { breakpoint: 900, settings: { slidesToShow: 7, slidesToScroll: 6 } },
                    { breakpoint: 700, settings: { slidesToShow: 6, slidesToScroll: 5 } },
                    { breakpoint: 610, settings: { slidesToShow: 5, slidesToScroll: 4 } },
                    { breakpoint: 550, settings: { slidesToShow: 4, slidesToScroll: 3 } },
                    { breakpoint: 460, settings: { slidesToShow: 3, slidesToScroll: 2 } },
                    { breakpoint: 360, settings: { slidesToShow: 2, slidesToScroll: 1 } },
                    { breakpoint: 280, settings: { slidesToShow: 1, slidesToScroll: 1 } },
                ]}
            >
                {
                    days.map(d =>
                        <BookingNightGridItem
                            key={d.day.toString()}
                            day={d}
                        />
                    )
                }
            </ComponentCarousel>
        )
    }
}

export default withTranslation("menu")(BookingNightGrid);
