// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.

/**
 * Class to interact with local storage
 */
abstract class AppStorage {
    readonly storageKey: string = "APOLLO";

    /**
     * Retrieved local object
     */
    protected jsonObject: any;

    /**
     * Constructor which loads stored object from local storage
     */
    constructor() {
        this.load();
    }

    /**
     * Retrieves object stored in storage (json format), load into memory
     */
    abstract load(): void;

    /**
     * Return applicable property if available in the stored object
     */
    public get(property: string): any {
        if (this.jsonObject.hasOwnProperty(property))
            return this.jsonObject[property];

        return null;
    }

    /**
     * Set a property of our in memory object
     * @param property Property name
     * @param value Property value to be serialized
     */
    public set(property: string, value: any): AppStorage {
        this.jsonObject[property] = value;

        return this;
    }

    /**
     * Save information to storage as json
     */
    abstract save(): void;
}

export default AppStorage;