// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MdMenu, MdSearch } from 'react-icons/md';
import { UserModel } from 'user';
import MenuDrawer from './menus/menu-drawer';
import styles from "./styles/client.less";

interface Props extends WithTranslation {
    user: UserModel;
    refreshUser: () => Promise<void>;
    refreshSystemMessages: () => void;
    hasSystemMessages: boolean;
}

interface State {
    menuVisible: boolean;
    initialPane: null | string;
}

class ClientHeader extends Component<Props, State> {
    switchPane: (s?: string) => void = () => { };

    constructor(props : Props) {
        super(props);

        this.state = {
            menuVisible: false,
            initialPane: null
        }
    }

    showMenu = (pane? : string) => {
        this.switchPane(pane);
        this.setState({ menuVisible: true });
    }

    hideMenu = () => {
        this.setState({ menuVisible: false });
    }

    setSwitchPane = (switchFunction: (s?: string) => void) => {
        this.switchPane = switchFunction;
    }    

    render() {
        const { t, user, refreshUser, refreshSystemMessages, hasSystemMessages } = this.props;

        return (user.branding !== undefined) ? ( 
            <header className={styles.headerClient} style={{ backgroundColor: user.styles.LOGOBACKGROUNDHEX, color: user.styles.LOGOTEXTHEX }}>
                <Row align="middle" justify="space-between" className={styles.rowMenu}>
                    <Col>
                        <a href="/Client">
                            <img className={styles.logo} src={user.branding.logoUrl} alt="" />
                        </a>
                    </Col>
                    <Col className={styles.colMenuItems}>
                        {user.isLoggedIn ?
                            (
                                <div className={styles.containerMenuItems}>
                                    <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem + " " + styles.navItemIcon} type="link" size="large" onClick={() => this.showMenu('search')}>
                                        <MdSearch />
                                    </Button>
                                    <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem + " " + styles.navItemIcon} type="link" size="large" onClick={() => this.showMenu()}>
                                        <MdMenu />
                                    </Button>
                                    <MenuDrawer user={user} refreshUser={refreshUser} open={this.state.menuVisible} onClose={this.hideMenu} setSwitchPane={this.setSwitchPane} refreshSystemMessages={refreshSystemMessages} hasSystemMessages={hasSystemMessages} />
                                </div>
                            ) : (
                                <div className={styles.containerMenuItems}>
                                    <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" size="large" href={user.baseUrl + '/Account/Register'}>
                                        {t('register')}
                                    </Button>
                                    <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" size="large" href={user.baseUrl + '/Account/Login'}>
                                        {t('logIn')}
                                    </Button>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </header>
        ) : (<span />)};
}


export default withTranslation('menu')(ClientHeader);