// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { FormRule } from "antd";
import { StoreValue } from "antd/es/form/interface";
import { TFunction } from "i18next";

const numberValidator = (rule: FormRule, value: StoreValue, t: TFunction, integer: boolean, min?: number, max?: number) => {
    let valueNumber = Number(value); 

    if (integer && !Number.isInteger(valueNumber)) {
        return Promise.reject(t("shared:validationFormat", { format: t("integer") }));
    } else if (!integer && !Number.isFinite(valueNumber)) {
        return Promise.reject(t("shared:validationFormat", { format: t("number") }));
    }    

    if (max === undefined && min !== undefined) {
        if (valueNumber >= min) {
            return Promise.resolve();
        }
        else {
            return Promise.reject(t("shared:validationMin", { min }));
        }
    }
    else if (min === undefined && max !== undefined) {
        if (valueNumber <= max) {
            return Promise.resolve();
        }
        else {
            return Promise.reject(t("shared:validationMax", { max }));
        }
    }
    else if (min !== undefined && max !== undefined) {
        if (valueNumber >= min && valueNumber <= max) {
            return Promise.resolve();
        }
        else {
            return Promise.reject(t("shared:validationBetween", { min, max }));
        }
    }
}

const mustCheckValidator = (rule: FormRule, value: StoreValue) => {
    if (value === true)
        return Promise.resolve();
    else
        return Promise.reject("");
}

export { numberValidator, mustCheckValidator }