// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from 'react';

import { Result } from 'antd'
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation { }

class Unauthorized extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return (
            <Result
                status="error"
                title={t("unauthorizedAccessTitle")}
                subTitle={t("unauthorizedAccessText")}
            />
        );
    }
}

export default withTranslation(["ui"])(Unauthorized);
