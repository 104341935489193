// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import { Checkbox, Col, Input, Row, Typography } from "antd";
import { i18next } from "translations";
import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ComparisonType } from "web-service";
import { filterConstants, FilterInputProps, FilterValue } from "../data";
import FilterListInputLabel from "./filter-list-input-label";
import styles from "./styles/filter-list-textbox.module.less";

const { Text } = Typography;

interface Props extends FilterInputProps<string>, WithTranslation {
    editStep?: string | undefined;
    firstNameFilter: string;
    lastNameFilter: string;
    table?: boolean;
}

interface State {
    visible: boolean;
}

class FilterListName extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { valueMap, firstNameFilter, lastNameFilter, showVisibilityToggle } = props;

        const trueFirstValue = valueMap?.get(firstNameFilter ?? "") ?? [];
        const trueLastValue = valueMap?.get(lastNameFilter ?? "") ?? [];

        this.state = {
            visible: (showVisibilityToggle === undefined
                || showVisibilityToggle === false
                || (showVisibilityToggle
                    && (
                        (trueFirstValue !== undefined && trueFirstValue.length > 0)
                        || (trueLastValue !== undefined && trueLastValue.length > 0)
                    )
                ))
        };
    }

    /**
     * Process input on change value
     * @param e Input value
     */
    handleChange = (e: React.ChangeEvent<HTMLInputElement>, filter: string, ix: number) => {
        const { values, onChange } = this.props;
        const stringValue = e.target.value ?? "";

        const newValues = cloneDeep(values ?? []);
        const value = new FilterValue<string>().assign(stringValue, stringValue, undefined, ComparisonType.Equal);

        while (newValues.length < ix)
            newValues.push(new FilterValue());

        newValues[ix] = value;

        if (onChange && filter)
            onChange(filter, newValues);
    };

    handleVisibilityChange = () => {
        const { filter, onChange, valueMap, firstNameFilter, lastNameFilter } = this.props;
        const { visible } = this.state;

        const trueFirstValue = valueMap?.get(firstNameFilter) ?? [];
        const trueLastValue = valueMap?.get(lastNameFilter) ?? [];

        const newVisible = !(visible || trueFirstValue.length > 0 || trueLastValue.length > 0);

        if (!newVisible && filter && onChange) {
            onChange(firstNameFilter, []);
            onChange(lastNameFilter, []);
        }

        this.setState({ visible: newVisible });
    }

    render() {
        const { label, valueMap, editStep, firstNameFilter, lastNameFilter, showVisibilityToggle, table } = this.props;
        const { visible } = this.state;

        const trueFirstValue = valueMap?.get(firstNameFilter) ?? [];
        const trueLastValue = valueMap?.get(lastNameFilter) ?? [];

        return (
            <Row align="middle" justify="space-between" gutter={[0, 8]}>
                <Col flex="auto">
                    {
                        showVisibilityToggle ?
                            <Checkbox
                                checked={(visible || trueFirstValue.length > 0 || trueLastValue.length > 0)}
                                onChange={this.handleVisibilityChange}
                            >
                                <Text>{label}</Text>
                            </Checkbox>
                            :
                            <Text className={styles.textColor} strong>{label}</Text>
                    }
                </Col>
                <Col aria-hidden style={{ minHeight: 34 }}></Col>
                {
                    (visible || trueFirstValue.length > 0 || trueLastValue.length > 0) &&
                    <Col span={24}>
                        <Row gutter={[0, 8]} className={styles.filterListName}>
                            <Col span={24}>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    <FilterListInputLabel<string>
                                        {...this.props}
                                        label={i18next.t("ui:firstName")}
                                        filter={firstNameFilter}
                                        values={trueFirstValue}
                                        open
                                        onOpenChange={this.handleVisibilityChange}
                                        options={filterConstants.textOptions}
                                        showVisibilityToggle={false}
                                        table={table}
                                    />
                                    <Col span={24}>
                                        <Input
                                            className={styles.input}
                                            style={editStep ? {} : { marginRight: "1em", maxWidth: "100%" }}
                                            type="textbox"
                                            placeholder={label?.toString()}
                                            onChange={(e) => this.handleChange(e, firstNameFilter, 0)}
                                            value={trueFirstValue.length > 0 ? trueFirstValue[0].value : ""}
                                            allowClear={true}
                                            role="textbox"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    <FilterListInputLabel<string>
                                        {...this.props}
                                        label={i18next.t("ui:lastName")}
                                        filter={lastNameFilter}
                                        values={trueLastValue}
                                        open={true}
                                        onOpenChange={this.handleVisibilityChange}
                                        options={filterConstants.textOptions}
                                        showVisibilityToggle={false}
                                        table={table}
                                    />
                                    <Col span={24}>
                                        <Input
                                            className={styles.input}
                                            style={editStep ? {} : { marginRight: "1em", maxWidth: "100%" }}
                                            type="textbox"
                                            placeholder={label?.toString()}
                                            onChange={(e) => this.handleChange(e, lastNameFilter, 0)}
                                            value={trueLastValue.length > 0 ? trueLastValue[0].value : ""}
                                            allowClear={true}
                                            role="textbox"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                }
            </Row>
        );
    }
}

export default withTranslation(["ui"])(FilterListName);