// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { permissionLookup } from "security";
import { TFunction } from "i18next";
import { UserModel } from "user";
import SearchItem from "./search-item";
import { Feature } from "web-service";

let pageList = function (user: UserModel, t: TFunction): Array<SearchItem> {

    var result : Array<SearchItem> = [];

    if (user.event != null) {
        if (user.event.eventPermissions.includes(permissionLookup.EVENT_MANAGEMENT)) {
            result.push({
                type: 4,
                name: t("generalSettings"),
                detail: "",
                description: t("event"),
                description2: null,
                description3: null,
                number: "",
                id: "/Wizard/Index/Event",
                category: "Event",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("features"),
                detail: "",
                description: t("event"),
                description2: null,
                description3: null,
                number: "",
                id: "/Wizard/Index/Features",
                category: "Event",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("settings"),
                detail: "",
                description: t("event"),
                description2: null,
                description3: null,
                number: "",
                id: "/Wizard/Index/Settings",
                category: "Event",
                favorite: false
            });
            if (user.event.eventFeatures?.includes(Feature.ContractedBlocks))
                result.push({
                    type: 4,
                    name: t("payment"),
                    detail: "",
                    description: t("event"),
                    description2: null,
                    description3: null,
                    number: "",
                    id: "/Wizard/Index/Payment",
                    category: "Event",
                    favorite: false
                });
            if (user.event.eventFeatures?.includes(Feature.ContractedBlocks))
                result.push({
                    type: 4,
                    name: t("hotels"),    
                    detail: "",
                    description: t("event"),
                    description2: null,
                    description3: null,
                    number: "",
                    id: "/Wizard/Index/Hotels",
                    category: "Event",
                    favorite: false
                });
            if (user.event.eventFeatures?.includes(Feature.NonContractedBlocks))
                result.push({
                    type: 4,
                    name: t("openBlock"),
                    detail: "",
                    description: t("event"),
                    description2: null,
                    description3: null,
                    number: "",
                    id: "/Wizard/Index/Gds",
                    category: "Event",
                    favorite: false
                });
            if (user.event.eventFeatures?.includes(Feature.ContractedBlocks))
                result.push({
                    type: 4,
                    name: t("roomBlocks"),
                    detail: "",
                    description: t("event"),
                    description2: null,
                    description3: null,
                    number: "",
                    id: "/Wizard/Index/Blocks",
                    category: "Event",
                    favorite: false
                });
            result.push({
                type: 4,
                name: t("subBlocks"),
                detail: "",
                description: t("event"),
                description2: null,
                description3: null,
                number: "",
                id: "/Wizard/Index/SubBlocks",
                category: "Event",
                favorite: false
            });
            if (user.event.eventFeatures?.includes(Feature.ContractedBlocks))
                result.push({
                    type: 4,
                    name: t("allotment"),
                    detail: "",
                    description: t("event"),
                    description2: null,
                    description3: null,
                    number: "",
                    id: "/Wizard/Index/Allotment",
                    category: "Event",
                    favorite: false
                });
            result.push({
                type: 4,
                name: t("design"),
                detail: "",
                description: t("event"),
                description2: null,
                description3: null,
                number: "",
                id: "/Wizard/Index/Design",
                category: "Event",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("links"),
                detail: "",
                description: t("event"),
                description2: null,
                description3: null,
                number: "",
                id: "/Wizard/Index/Links",
                category: "Event",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("summary"),
                detail: "",
                description: t("event"),
                description2: null,
                description3: null,
                number: "",
                id: "/Wizard/Index/Summary",
                category: "Event",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("status"),
                detail: "",
                description: t("event"),
                description2: null,
                description3: null,
                number: "",
                id: "/Event/Status",
                category: "Event",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("waitListSettings"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/WaitList/Settings",
                category: "Advanced",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("waitListRequests"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/WaitList/Manage",
                category: "Advanced",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("promotionCodes"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/Promotion",
                category: "Advanced",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("accessCodes"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/AccessCode",
                category: "Advanced",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("integrations"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/Integration",
                category: "Advanced",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("finalHotelPickup"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/FinalPickup",
                category: "Advanced",
                favorite: false
            });
            if (user.event.eventFeatures?.includes(Feature.EventMultiLanguage))
                result.push({
                    type: 4,
                    name: t("translations"),
                    detail: "",
                    description: t("advanced"),
                    description2: null,
                    description3: null,
                    number: "",
                    id: "/Language/Manage",
                    category: "Advanced",
                    favorite: false
                });
            result.push({
                type: 4,
                name: t("hotelOrderAndAds"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/Event/HotelOrder",
                category: "Advanced",
                favorite: false
            });
            result.push({
                type: 4,
                name: t("marketplace"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/Event/Marketplaces",
                category: "Advanced",
                favorite: false
            });
        }

        if (user.event.eventPermissions.includes(permissionLookup.ROOMING_LIST_MANAGEMENT)) {
            result.push({
                type: 4,           
                name: t("roomingList"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/RoomingList",
                category: "Advanced",
                favorite: false
            });
        }

        if (user.event.eventPermissions.includes(permissionLookup.REPORT_SHAREABLE)) {
            result.push({
                type: 4,
                name: t("scheduledReports"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/Report/ScheduledReports",
                category: "Advanced",
                favorite: false
            });
        }


        if (user.event.eventPermissions.includes(permissionLookup.RESERVATION_VIEW)) {
            result.push({
                type: 4,
                name: t("eventReservations"),
                detail: "",
                description: t("booking"),
                description2: null,
                description3: null,
                number: "",
                id: "/Booking/Search",
                category: "Booking",
                favorite: false
            });
        }

        if (user.event.eventPermissions.includes(permissionLookup.RESERVATION_EDIT)) {
            result.push({
                type: 4,
                name: t("bookingGrid"),
                detail: "",
                description: t("advanced"),
                description2: null,
                description3: null,
                number: "",
                id: "/Booking/Grid",
                category: "Advanced",
                favorite: false
            });
        }

        if (user.event.eventPermissions.includes(permissionLookup.USER_MANAGEMENT)) {
            result.push({
                type: 4,
                name: t("users"),
                detail: "",
                description: t("user"),
                description2: null,
                description3: null,
                number: "",
                id: "/Manage",
                category: "User",
                favorite: false
            });
        }  
    }

    if (user.clientPermissions.includes(permissionLookup.EVENT_MANAGEMENT)) {
        result.push({
            type: 4,
            name: t("newEvent"),
            detail: "",
            description: t("event") + " ",
            description2: null,
            description3: null,
            number: "",
            id: "/Wizard",
            category: "Event",
            favorite: false
        });
    }

    result.push({
        type: 4,
        name: t("notifications"),
        detail: "",
        description: t("notification"),
        description2: null,
        description3: null,
        number: "",
        id: "/Notification",
        category: "User",
        favorite: false
    });   

    result.push({
        type: 4,
        name: t("account"),
        detail: "",
        description: t("user"),
        description2: null,
        description3: null,
        number: "",
        id: "/Manage/MyAccount",
        category: "User",
        favorite: false
    });   

    return result;
};

export default pageList;