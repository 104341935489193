// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import sanitize from "sanitize-html";

const acceptedHtmlOptions: sanitize.IOptions = {
    allowedTags: [
        "p", "span",
        "hr", "br", "img", "blockquote",
        "h1", "h2", "h3", "h4", "h5", "h6",
        "strong", "em", "u", "s", 
        "ul", "li", "ol",
        "a"
    ],
    allowedAttributes: {
        "a": ["href", "rel", "target", "style"],
        ...["p", "span", "li", "strong", "em", "u", "s", "h1", "h2", "h3", "h4", "h5", "h6"].reduce((tagList, tag) => {
            tagList[tag] = ["class", "style"];
            return tagList;
        }, {} as Record<string, sanitize.AllowedAttribute[]>)
    },
};

export { acceptedHtmlOptions };
