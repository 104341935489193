// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Card } from "antd";
import { dayjs } from "translations";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiCheck } from "react-icons/bi";
import { BookingDay, BookingType } from "web-service";
import styles from "./styles/booking-night-grid.less";
import clsx from "clsx";


interface Props extends WithTranslation {
    day: BookingDay;
}

class BookingNightGridItem extends Component<Props> {
    render() {
        const { t, day } = this.props;
        const date = dayjs.utc(day.day);

        return (
            <Card
                bordered
                size="small"
                className={clsx(styles.dateCard, {
                    [styles.waitlist]: day.bookingType === BookingType.WaitList,
                    [styles.reserved]: day.bookingType === BookingType.Reservation
                })}
            >
                <span>{t("dateShort", { date: date })}</span>
                {
                    day.bookingType === BookingType.WaitList
                        ? <AiOutlineClockCircle />
                        : <BiCheck />
                }
            </Card>
        );
    }
}

export default withTranslation("menu")(BookingNightGridItem);
