// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import * as FileSaver from "file-saver";

class ServiceClient {
    readonly baseUrl: string = '/Api';
    requestVerificationToken = '';

    constructor() {
        const tokenElements = document.getElementsByName("__RequestVerificationToken");

        //These verification tokens will only be necessary until we switch to bearer token authentication.
        if (tokenElements.length) {
            this.requestVerificationToken = (tokenElements[0] as HTMLInputElement).value;
        }
    }
    private fetch(url: string, options: RequestInit) {
        return fetch(url, options)
            .then(response =>
                response.json().then(result => ({ result, response }))
            ).then(({ result, response }) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return result;
            });
    }
    public get(action: string, controller: string) {
        const options : RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            credentials: "same-origin" //Replace with access tokens when we go React only.
        };

        return { promise: this.fetch(this.baseUrl + "/" + controller + "/" + action, options) };
    }
    public post(action: string, controller: string, data: any) {

        const abortController = new AbortController();
        const signal = abortController.signal;

        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'RequestVerificationToken': this.requestVerificationToken
            },
            credentials: "same-origin",  //Replace with access tokens when we go React only.
            body: JSON.stringify(data),
            signal: signal
        };


        return { promise: this.fetch(this.baseUrl + "/" + controller + "/" + action, options), controller: abortController };
    }

    public fetchFile(action: string, controller: string, data: any, mimeType :string, fileName :string) {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'RequestVerificationToken': this.requestVerificationToken
            },
            credentials: "same-origin",  //Replace with access tokens when we go React only.
            body: JSON.stringify(data),
            signal: signal
        };

        let url = this.baseUrl + "/" + controller + "/" + action;
        return fetch(url, options)
            .then(res => {
                return res.arrayBuffer()
            })
            .then((res: any) => {
                let data = new Blob([res], {
                    type: mimeType
                });

                FileSaver.saveAs(data, fileName);
                return { success: true, error: null }
            }).catch(err => {
                return { success: false, error: err }
            });
    }

    public postForm(action: string, controller: string, data: any) {

        const abortController = new AbortController();
        const signal = abortController.signal;

        const options: RequestInit = {
            method: 'POST',
            headers: {
                'RequestVerificationToken': this.requestVerificationToken
            },
            credentials: "same-origin",  //Replace with access tokens when we go React only.p
            body: data,
            signal: signal
        };
        
        return { promise: this.fetch(this.baseUrl + "/" + controller + "/" + action, options), controller: abortController };
    }
};

const serviceClient = new ServiceClient();

export default serviceClient;