// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import clsx from "clsx";
import React, { Component, ReactNode } from "react";
import styles from "./styles/input-label.module.less";

interface Props {
    className?: string;
    required?: boolean;
    style?: React.CSSProperties;
    htmlFor?: string;
    id?: string;
    children?: ReactNode;
}

class InputLabel extends Component<Props> {

    render() {
        const { className, required = false, children, ...rest } = this.props;

        return <label className={clsx([styles.inputLabel, className, { [styles.required]: required }])} {...rest}>{children}</label>;
    }
}

export default InputLabel;