// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Alert, Button, Checkbox, Col, Drawer, Form, FormInstance, Input, Row, Select } from 'antd';
import { Store } from 'antd/es/form/interface';
import { validationConstants } from 'configuration';
import { TFunction } from "i18next";
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserModel } from 'user';
import { serviceClient } from 'web-service';
import FeedbackType from '../interfaces/feedback-type';
import { withApp, WithApp } from "state";

const { Option } = Select;
const { TextArea } = Input;

interface Props extends WithTranslation, WithApp {
    user: UserModel;
    open: boolean;
    onClose: () => void;
    defaultType?: FeedbackType;
}

interface State {
    error: boolean;
    loading: boolean;
}

class FeedbackDrawer extends Component<Props, State> {
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            error: false
        };
    }

    handleFinish = (values: Store) => {

            this.setState({
                loading: true,
                error: false
            });

            serviceClient.post("Feedback", "Help", {
                fullName: values.fullName,
                email: values.email,
                phone: values.phone,
                feedbackType: values.type,
                feedback: values.feedback,
                canContact: values.canContact,
                url: document.location.href,
                eventID: this.props.user.event == null ? null : this.props.user.event.id
            }).promise
                .then(() => {
                    this.setState({
                        error: false,
                        loading: false
                    });
                    this.props.onClose();
                    this.formRef.current?.resetFields();

                    this.showSuccess();
                })
                .catch(() => {
                    this.setState({
                        error: true,
                        loading: false
                    });
                });
    }

    getTypes = (t : TFunction) => {
        return [
            <Option key="10" value={FeedbackType.General}>{t("typeGeneral")}</Option>,
            <Option key="20" value={FeedbackType.Bug}>{t("typeBug")}</Option>,
            <Option key="30" value={FeedbackType.Suggestion}>{t("typeSuggestion")}</Option>,
            <Option key="40" value={FeedbackType.Feature}>{t("typeFeature")}</Option>,
            <Option key="50" value={FeedbackType.Content}>{t("typeContent")}</Option>,
            <Option key="70" value={FeedbackType.Sales}>{t("typeSales")}</Option>,
            <Option key="60" value={FeedbackType.Other}>{t("typeOther")}</Option>
        ];
    }

    showSuccess = () => {
        const { app: { notification } } = this.props;
        notification.open({
            message: this.props.t('feedbackSent'),
            description:
                this.props.t('feedbackSentText')
        });
    }

    render() {
        const { open, onClose, user, t, defaultType, breakpoints } = this.props;
        const { loading } = this.state;

        return (
            <Drawer
                title={t("submitFeedback")}
                open={open}
                onClose={onClose}
                placement={breakpoints.lg ? "right" : "left"}
                width={breakpoints.lg ? 500: "95%"}
            >
                <Form scrollToFirstError={true} layout="vertical" onFinish={this.handleFinish} ref={this.formRef} initialValues={
                    {
                        fullName: user.firstName + ' ' + user.lastName,
                        email: user.email,
                        type: defaultType ?? FeedbackType.General
                    }
                }>
                    <Row gutter={16}>
                        {
                            this.state.error &&
                            <Col span={24}>
                                <Alert
                                    message={t("shared:error")}
                                    description={t("shared:genericError")}
                                    type="error"
                                    style={{ marginBottom: "24px" }}
                                />
                            </Col>
                        }

                        <Col span={24}>
                            <Form.Item name="fullName" label={t("fullName")} rules={[{ required: true, message: t("shared:validationRequired", { field: t("fullName") }) }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item name="email" label={t("email")} rules={[
                                { required: true, message: t("shared:validationRequired", { field: t("email") }) },
                                { pattern: validationConstants.emailRegEx, message: t("shared:validationFormat", { format: t("emailAddress") }) }
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item name="phone" label={t("phone")} rules={[{pattern: validationConstants.phoneRegEx, message: t("shared:validationFormat", {format: t("phoneNumber") }) }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="type" label={t("type")}>
                                <Select getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement}>
                                    {this.getTypes(t)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="feedback" label={t("feedback")} rules={[{required: true, message: t("shared:validationRequired", {field: t("feedback") }) }]}>
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="canContact" valuePropName="checked">
                                <Checkbox>{t("canContact")}</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {t("submit")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        );
    }
}

export default withApp(withTranslation(["menu", "shared"])(FeedbackDrawer));
