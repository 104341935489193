// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from 'react';
import { Input, InputProps } from 'antd';
import InputEditor, { inputEditorStyles } from './input-editor';
import InputNumberEditor from './input-number-editor';
import InputAddon from './input-addon';

class UiInput extends Component<InputProps> {
    constructor(props: InputProps) {
        super(props);
    }

    render() {
        const { className, ...other } = this.props;

        return (
            <Input {...other} className={className || ''} />
        );
    }
}

export default UiInput
export { InputEditor, InputNumberEditor, inputEditorStyles, InputAddon }
