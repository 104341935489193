// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import i18next from "../i18next";

i18next.addResourceBundle("en", "menu", {
    privacyRequest: "Global Privacy Request",
    termsOfService: "Terms of Service",
    privacyPolicy: "Privacy Policy",
    clientHome: "Client Home",
    events: "Events",
    live: "Live",
    preview: "Preview",
    demo: "Demo",
    dashboard: "Dashboard",
    reports: "Reports",
    pages: "Pages",
    all: "All",
    newReservation: "New Reservation",
    manage: "Manage",
    advanced: "Advanced",
    search: "Search",
    searchPlaceholder: "Search (Reservations, Events, Pages, Reports)",
    searchEvents: "Search All Events",
    account: "Account",
    register: "Sign Up",
    logIn: "Log In",
    logOut: "Log Out",
    allResults: "All Results",
    reservations: "Reservations",
    reservationsShort: "Res.",
    pagesReports: "Pages & Reports",
    event: "Event",
    public: "Public",
    private: "Private",
    newEvent: "New Event",
    createNewEvent: "New Event",
    booking: "Booking",
    user: "User",
    notification: "Notification",
    reservation: "Reservation",
    page: "Page",
    report: "Report",
    generalSettings: "Edit Event",
    features: "Features",
    settings: "Settings",
    hotels: "Hotels",
    roomBlocks: "Room Blocks",
    subBlocks: "Sub Blocks",
    allotment: "Allotment",
    summary: "Summary",
    payment: "Payment",
    design: "Designer",
    links: "Links",
    status: "Status",
    waitListSettings: "Waitlist Settings",
    waitListRequests: "Waitlist Requests",
    promotionCodes: "Discount Codes",
    accessCodes: "Access Codes",
    integrations: "Integrations",
    finalHotelPickup: "Final Hotel Pickup",
    roomingList: "Rooming List",
    scheduledReports: "Scheduled Reports",
    users: "Users",
    userManagement: "User Management",
    notifications: "Notifications",
    manageNotifications: "Manage",
    supportFeedBack: "Support & Feedback",
    statusPreview: "Preview",
    statusDemo: "Demo",
    statusLive: "Live",
    statusArchived: "Archived",
    edit: "edit",
    eventSiteLinks: "Event Site Links",
    editSiteLinks: "Site Links",
    reservationSiteLink: "Reservation Site Link",
    copy: "Copy",
    copied: "Copied",
    open: "Open",
    emptyResults: "No Results Found",
    emptyResultsEvents: "No Events Found",
    emptyResultsReservations: "No Reservations Found",
    filterByEvent: "Filter by Event",
    emptyNotifications: "No Current Notifications",
    viewAllNotifications: "View All",
    notificationsDisabled: "You are currently not subscribed to receive notifications for this event.",
    enableNotifications: "Turn on notifications for this event",
    eventId: "Event ID",
    translations: "Translations",
    bookingGrid: "Bulk Reservations",
    allEvents: "All Events",
    previewAndDemoEvents: "Preview/Demo Events",
    liveEvents: "Live Events",
    archivedEvents: "Archived Events",
    subBlockGroup: "Sub Block Group",
    checkInOut: "Check In - Out",
    guestNames: "Guest Names",
    hotelAndRoom: "Hotel & Room",
    viewDetails: "View Details",
    hideDetails: "Hide Details",
    cloneEvent: "Clone Event",
    pending: "Pending",
    complete: "Complete",
    cancelled: "Cancelled",
    completed: "Completed",
    abandoned: "Abandoned",
    hotelOrderAndAds: "Hotel Order & Ads",
    abandonedRooms: "Abandoned Rooms",
    submitFeedback: 'Submit Feedback',
    fullName: 'Full Name',
    email: 'Email',
    phone: 'Phone',
    type: 'Type of Feedback',
    feedback: 'Feedback',
    canContact: 'May we contact you regarding your feedback?',
    cancel: 'Cancel',
    submit: 'Submit',
    typeGeneral: 'General Feedback',
    typeBug: 'Bug Report',
    typeSuggestion: 'Suggestion',
    typeFeature: 'Feature Request',
    typeContent: 'Content Error (Spelling/Grammar)',
    typeOther: 'Other',
    emailAddress: 'email address',
    phoneNumber: 'phone number',
    feedbackSent: 'Feedback Sent',
    feedbackSentText: 'Thank you for your feedback, it has been received successfully.',
    typeSales: 'Sales',
    cloneEventDescription: "After selecting your options below you'll be redirected to the edit event screen for your cloned event. All fields will be filled out for you.",
    eventToClone: "Event to Clone",
    client: "Client",
    newEventName: "New Event Name",
    startDate: "Start Date",
    endDate: "End Date",
    create: "Create",
    marketplace: "Marketplace",
    details: "Details",
    subBlock: "Sub Block",
    checkInCheckOut: "Check In | Check Out",
    dateShort: "{{ date, MMM DD }}",
    dateShortDY: "{{ date, DD, YYYY}}",
    dateShortFull: "{{ date, MMM DD, YYYY}}",
    dateConcat: "{{startDate}} - {{endDate}}",
    allReservations: "All Reservations",
    eventReservations: "Event Reservations",
    openBlock: "Open Block",
    testingSite: "Testing site",
    welcomeTestSite: "Welcome to our testing site!",
    welcomeTestSiteDescTextOne: "Kindly note that any reservations placed here are for demonstration purposes <strong>ONLY</strong> and will be automatically <strong>DELETED</strong> within 24 hours.",
    welcomeTestSiteDescTextTwo: "If you accessed this site and believe there is an error, please feel free to reach out to us at <mail>{{ email }}</mail> or by phone <tel>{{ phone }}</tel> for further assistance.",
    welcomeTestSiteDescTextTwoWithoutPhone: "If you accessed this site and believe there is an error, please feel free to reach out to us at <mail>{{ email }}</mail> for further assistance.",
    mailToHref: "mailto:{{email}}",
    telHref: "tel:{{phone}}",
    accept: "Accept",
    nRemaining: "{{n}} remaining",
    showSystemMessages: "Show System Messages",
    contract: "Contract"
});