// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from 'react';
import { UserContext } from 'user';

import Menu from './menu';

type Props = {};

class HeaderMenu extends Component {
    constructor(props: Props) {
        super(props); 
    }

    render() {
        return (
            <UserContext.Consumer>
                {({ user, refreshUser }) =>
                    <Menu user={user} refreshUser={refreshUser} />
                }
            </UserContext.Consumer>
        );
    }
}

export default HeaderMenu;