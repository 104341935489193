// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import i18next from '../i18next';

i18next.addResourceBundle('en', 'ui', {
    unauthorizedAccessTitle: "Sorry, you are not authorized to access this function.",
    unauthorizedAccessText: "Please contact your system administrator if you believe you have received this message in error.",
    select: "Select",
    nSelected: "{{ n }} Selected",
    plusnMore: "+{{ n }} More",
    oneDateSelected: "1 Date Selected",
    nDatesSelected: "{{ n }} Dates Selected",
    selectFormat: "Select Format",

    //Time Zones 
    UTC: "(UTC) Coordinated Universal Time",
    GMTStandardTime: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    GreenwichStandardTime: "(UTC+00:00) Monrovia, Reykjavik",
    SaoTomeStandardTime: "(UTC+00:00) Sao Tome",
    W_EuropeStandardTime: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    CentralEuropeStandardTime: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    RomanceStandardTime: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    MoroccoStandardTime: "(UTC+01:00) Casablanca",
    CentralEuropeanStandardTime: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    W_CentralAfricaStandardTime: "(UTC+01:00) West Central Africa",
    JordanStandardTime: "(UTC+02:00) Amman",
    GTBStandardTime: "(UTC+02:00) Athens, Bucharest",
    MiddleEastStandardTime: "(UTC+02:00) Beirut",
    EgyptStandardTime: "(UTC+02:00) Cairo",
    E_EuropeStandardTime: "(UTC+02:00) Chisinau",
    SyriaStandardTime: "(UTC+02:00) Damascus",
    WestBankStandardTime: "(UTC+02:00) Gaza, Hebron",
    SouthAfricaStandardTime: "(UTC+02:00) Harare, Pretoria",
    FLEStandardTime: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    IsraelStandardTime: "(UTC+02:00) Jerusalem",
    KaliningradStandardTime: "(UTC+02:00) Kaliningrad",
    SudanStandardTime: "(UTC+02:00) Khartoum",
    LibyaStandardTime: "(UTC+02:00) Tripoli",
    NamibiaStandardTime: "(UTC+02:00) Windhoek",
    ArabicStandardTime: "(UTC+03:00) Baghdad",
    TurkeyStandardTime: "(UTC+03:00) Istanbul",
    ArabStandardTime: "(UTC+03:00) Kuwait, Riyadh",
    BelarusStandardTime: "(UTC+03:00) Minsk",
    RussianStandardTime: "(UTC+03:00) Moscow, St. Petersburg",
    E_AfricaStandardTime: "(UTC+03:00) Nairobi",
    IranStandardTime: "(UTC+03:30) Tehran",
    ArabianStandardTime: "(UTC+04:00) Abu Dhabi, Muscat",
    AstrakhanStandardTime: "(UTC+04:00) Astrakhan, Ulyanovsk",
    AzerbaijanStandardTime: "(UTC+04:00) Baku",
    RussiaTimeZone3: "(UTC+04:00) Izhevsk, Samara",
    MauritiusStandardTime: "(UTC+04:00) Port Louis",
    SaratovStandardTime: "(UTC+04:00) Saratov",
    GeorgianStandardTime: "(UTC+04:00) Tbilisi",
    VolgogradStandardTime: "(UTC+04:00) Volgograd",
    CaucasusStandardTime: "(UTC+04:00) Yerevan",
    AfghanistanStandardTime: "(UTC+04:30) Kabul",
    WestAsiaStandardTime: "(UTC+05:00) Ashgabat, Tashkent",
    EkaterinburgStandardTime: "(UTC+05:00) Ekaterinburg",
    PakistanStandardTime: "(UTC+05:00) Islamabad, Karachi",
    QyzylordaStandardTime: "(UTC+05:00) Qyzylorda",
    IndiaStandardTime: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    SriLankaStandardTime: "(UTC+05:30) Sri Jayawardenepura",
    NepalStandardTime: "(UTC+05:45) Kathmandu",
    CentralAsiaStandardTime: "(UTC+06:00) Astana",
    BangladeshStandardTime: "(UTC+06:00) Dhaka",
    OmskStandardTime: "(UTC+06:00) Omsk",
    MyanmarStandardTime: "(UTC+06:30) Yangon (Rangoon)",
    SEAsiaStandardTime: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    AltaiStandardTime: "(UTC+07:00) Barnaul, Gorno-Altaysk",
    W_MongoliaStandardTime: "(UTC+07:00) Hovd",
    NorthAsiaStandardTime: "(UTC+07:00) Krasnoyarsk",
    N_CentralAsiaStandardTime: "(UTC+07:00) Novosibirsk",
    TomskStandardTime: "(UTC+07:00) Tomsk",
    ChinaStandardTime: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    NorthAsiaEastStandardTime: "(UTC+08:00) Irkutsk",
    SingaporeStandardTime: "(UTC+08:00) Kuala Lumpur, Singapore",
    W_AustraliaStandardTime: "(UTC+08:00) Perth",
    TaipeiStandardTime: "(UTC+08:00) Taipei",
    UlaanbaatarStandardTime: "(UTC+08:00) Ulaanbaatar",
    AusCentralW_StandardTime: "(UTC+08:45) Eucla",
    TransbaikalStandardTime: "(UTC+09:00) Chita",
    TokyoStandardTime: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    NorthKoreaStandardTime: "(UTC+09:00) Pyongyang",
    KoreaStandardTime: "(UTC+09:00) Seoul",
    YakutskStandardTime: "(UTC+09:00) Yakutsk",
    Cen_AustraliaStandardTime: "(UTC+09:30) Adelaide",
    AUSCentralStandardTime: "(UTC+09:30) Darwin",
    E_AustraliaStandardTime: "(UTC+10:00) Brisbane",
    AUSEasternStandardTime: "(UTC+10:00) Canberra, Melbourne, Sydney",
    WestPacificStandardTime: "(UTC+10:00) Guam, Port Moresby",
    TasmaniaStandardTime: "(UTC+10:00) Hobart",
    VladivostokStandardTime: "(UTC+10:00) Vladivostok",
    LordHoweStandardTime: "(UTC+10:30) Lord Howe Island",
    BougainvilleStandardTime: "(UTC+11:00) Bougainville Island",
    RussiaTimeZone10: "(UTC+11:00) Chokurdakh",
    MagadanStandardTime: "(UTC+11:00) Magadan",
    NorfolkStandardTime: "(UTC+11:00) Norfolk Island",
    SakhalinStandardTime: "(UTC+11:00) Sakhalin",
    CentralPacificStandardTime: "(UTC+11:00) Solomon Is., New Caledonia",
    RussiaTimeZone11: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
    NewZealandStandardTime: "(UTC+12:00) Auckland, Wellington",
    UTC12: "(UTC+12:00) Coordinated Universal Time+12",
    FijiStandardTime: "(UTC+12:00) Fiji",
    KamchatkaStandardTime: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
    ChathamIslandsStandardTime: "(UTC+12:45) Chatham Islands",
    UTC13: "(UTC+13:00) Coordinated Universal Time+13",
    TongaStandardTime: "(UTC+13:00) Nuku'alofa",
    SamoaStandardTime: "(UTC+13:00) Samoa",
    LineIslandsStandardTime: "(UTC+14:00) Kiritimati Island",
    AzoresStandardTime: "(UTC-01:00) Azores",
    CapeVerdeStandardTime: "(UTC-01:00) Cabo Verde Is.",
    UTC02: "(UTC-02:00) Coordinated Universal Time-02",
    MidAtlanticStandardTime: "(UTC-02:00) Mid-Atlantic - Old",
    TocantinsStandardTime: "(UTC-03:00) Araguaina",
    E_SouthAmericaStandardTime: "(UTC-03:00) Brasilia",
    SAEasternStandardTime: "(UTC-03:00) Cayenne, Fortaleza",
    ArgentinaStandardTime: "(UTC-03:00) City of Buenos Aires",
    GreenlandStandardTime: "(UTC-03:00) Greenland",
    MontevideoStandardTime: "(UTC-03:00) Montevideo",
    MagallanesStandardTime: "(UTC-03:00) Punta Arenas",
    SaintPierreStandardTime: "(UTC-03:00) Saint Pierre and Miquelon",
    BahiaStandardTime: "(UTC-03:00) Salvador",
    NewfoundlandStandardTime: "(UTC-03:30) Newfoundland",
    ParaguayStandardTime: "(UTC-04:00) Asuncion",
    AtlanticStandardTime: "(UTC-04:00) Atlantic Time (Canada)",
    VenezuelaStandardTime: "(UTC-04:00) Caracas",
    CentralBrazilianStandardTime: "(UTC-04:00) Cuiaba",
    SAWesternStandardTime: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    PacificSAStandardTime: "(UTC-04:00) Santiago",
    SAPacificStandardTime: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
    EasternStandardTimeMexico: "(UTC-05:00) Chetumal",
    EasternStandardTime: "(UTC-05:00) Eastern Time (US & Canada)",
    HaitiStandardTime: "(UTC-05:00) Haiti",
    CubaStandardTime: "(UTC-05:00) Havana",
    USEasternStandardTime: "(UTC-05:00) Indiana (East)",
    TurksAndCaicosStandardTime: "(UTC-05:00) Turks and Caicos",
    CentralAmericaStandardTime: "(UTC-06:00) Central America",
    CentralStandardTime: "(UTC-06:00) Central Time (US & Canada)",
    EasterIslandStandardTime: "(UTC-06:00) Easter Island",
    CentralStandardTimeMexico: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    CanadaCentralStandardTime: "(UTC-06:00) Saskatchewan",
    USMountainStandardTime: "(UTC-07:00) Arizona",
    MountainStandardTimeMexico: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    MountainStandardTime: "(UTC-07:00) Mountain Time (US & Canada)",
    PacificStandardTimeMexico: "(UTC-08:00) Baja California",
    UTC08: "(UTC-08:00) Coordinated Universal Time-08",
    PacificStandardTime: "(UTC-08:00) Pacific Time (US & Canada)",
    AlaskanStandardTime: "(UTC-09:00) Alaska",
    UTC09: "(UTC-09:00) Coordinated Universal Time-09",
    MarquesasStandardTime: "(UTC-09:30) Marquesas Islands",
    AleutianStandardTime: "(UTC-10:00) Aleutian Islands",
    HawaiianStandardTime: "(UTC-10:00) Hawaii",
    UTC11: "(UTC-11:00) Coordinated Universal Time-11",
    DatelineStandardTime: "(UTC-12:00) International Date Line West",
    nAvailable: "{{ n }} Available",
    nAvailablemFiltered: "$t(nAvailable), $t(mFiltered)",
    nAdded: "{{ n }} Added",
    nAddedmFiltered: "$t(nAdded), $t(mFiltered)",
    mFiltered: "{{ m }} Filtered",
    addAll: "Add All",
    addNFiltered: "Add {{ n }} Filtered",
    removeAll: "Remove All",
    removeNFiltered: "Remove {{ n }} Filtered",
    search: "Search",
    filters: "Filters",
    filterxy: "{{ x }}: {{ y }}",
    clearAll: "Clear All",
    equal: "Equal",
    notEqual: "Not Equal",
    contains: "Contains",
    notContains: "Not Contains",
    startsWith: "Starts With",
    endsWith: "Ends With",
    between: "Between",
    dateRange: "Date Range",
    filtersChanged: "What should we do with the new filters you have just added?",
    confirmFilters: "What about your new filters?",
    apply: "Apply",
    applyFilters: "Apply Filters",
    clearAllFilters: "Clear All Filters",
    dontApply: "Don't Apply",
    filterBy: "Filter By:",
    dragHandle: "Drag Handle",
    dragDisabledHint: "Clear search to reorder",
    contractIconTooltip: "Available for contracted block.",
    gdsIconTooltip: "Available for open block.",
    firstName: "First Name",
    lastName: "Last Name",
    removeFilter: "Remove Filter",
    saveAsNew: "Save As New",
    save: "Save",
    from: "From",
    to: "To",
});