// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from 'react';
import { Modal } from 'antd';
import { UserModel } from 'user';
import { serviceClient, SystemMessage, SystemMessageStyle } from 'web-service';
import { localAppStorage } from 'state';
import SystemMessageItem from './system-message-item';
import styles from './styles/system-message-list.module.less';
import { ComponentCarousel } from "ui";

const BANNER_CLOSED_COOKIE = "bannerClosed";
const POPUP_CLOSED_COOKIE = "popupClosed";

interface Props {
    user: UserModel;
    type?: SystemMessageStyle;
    refreshTime?: string;
    onSetHasSystemMessage?: (hasMessage: boolean) => void;
}

interface State {
    banners: Array<SystemMessage>;
    popups: Array<SystemMessage>;
    loginBanners: Array<SystemMessage>;
    loginMessages: Array<SystemMessage>;
    closedBannersIds: Array<string>;
    closedPopupIds: Array<string>;
}

class SystemMessageList extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            banners: [],
            popups: [],
            loginBanners: [],
            loginMessages: [],
            closedBannersIds: [],
            closedPopupIds: []
        };
    }

    componentDidUpdate = (prevProps: Props) => {
        const { user, type, refreshTime, onSetHasSystemMessage } = this.props;

        if (!prevProps.user.id && !!user.id || refreshTime !== prevProps.refreshTime) {
            onSetHasSystemMessage?.(false);
            if (user.isLoggedIn) {
                this.handleLoadSystemMessages(SystemMessageStyle.LoginBanner);
                this.handleLoadSystemMessages(SystemMessageStyle.Banner);
                this.handleLoadSystemMessages(SystemMessageStyle.Popup);
            } else {
                this.handleLoadSystemMessages(SystemMessageStyle.LoginBanner);
                if (document.getElementById('login-system-messages') as HTMLDivElement !== null && type === SystemMessageStyle.Login) {
                    this.handleLoadSystemMessages(SystemMessageStyle.Login);
                }
            }
        }
    }

    /**
     * Get the list of ids for closed messages
     * @param style
     */
    handleGetClosedMessageList = (style: SystemMessageStyle): string[] => {
        let result = "";
        switch (style) {
            case SystemMessageStyle.Popup:
                result = localAppStorage.get(POPUP_CLOSED_COOKIE);
                break;
            case SystemMessageStyle.Banner:
            case SystemMessageStyle.LoginBanner:
                result = localAppStorage.get(BANNER_CLOSED_COOKIE);
                break;
        }
        return result ? JSON.parse(result) : [];
    }

    /**
     * Save the list of ids for closed messages to local storage
     * @param style
     * @param closedIds
     */
    handleSaveClosedMessageList = (style: SystemMessageStyle, closedIds: string[]) => {
        const closedIdsString = JSON.stringify(closedIds);
        switch (style) {
            case SystemMessageStyle.Popup:
                localAppStorage.set(POPUP_CLOSED_COOKIE, closedIdsString).save();
                break;
            case SystemMessageStyle.Banner:
            case SystemMessageStyle.LoginBanner:
                localAppStorage.set(BANNER_CLOSED_COOKIE, closedIdsString).save();
                break;
        }
    }

    handleLoadSystemMessages = (style: SystemMessageStyle) => {
        const { onSetHasSystemMessage } = this.props;
        serviceClient.post("List", "SystemMessage", { style }).promise
            .then((messages: Array<SystemMessage>) => {
                if (messages.length === 0)
                    return;

                const closedIds = this.handleGetClosedMessageList(style);
                const openMessages = messages.filter(m => !closedIds.includes(m.id));

                if (style === SystemMessageStyle.Banner) {
                    this.setState({ banners: openMessages, closedBannersIds: closedIds });
                    onSetHasSystemMessage?.(true);
                }

                else if (style === SystemMessageStyle.LoginBanner) {
                    this.setState({loginBanners: openMessages, closedBannersIds: closedIds});
                    onSetHasSystemMessage?.(true);
                }

                else if (style === SystemMessageStyle.Popup) {
                    this.setState({popups: openMessages, closedPopupIds: closedIds});
                    onSetHasSystemMessage?.(true);
                }

                else if (style === SystemMessageStyle.Login)
                    this.setState({loginMessages: openMessages});
            });
    }

    handleCloseSystemMessage = (style: SystemMessageStyle, id?: string) => {
        const { banners, loginBanners, popups, closedBannersIds, closedPopupIds } = this.state;

        if (style === SystemMessageStyle.Banner && id) {
            const closedIds = [...closedBannersIds, id];
            this.handleSaveClosedMessageList(style, closedIds);
            this.setState({ banners: banners.filter(b => b.id !== id), closedBannersIds: closedIds });
        }
        else if (style === SystemMessageStyle.Popup) {
            const closedIds = [...closedPopupIds, ...popups.map(p => p.id)];
            this.handleSaveClosedMessageList(style, closedIds);
            this.setState({ popups: [], closedPopupIds: closedIds });
        }
        else if (style === SystemMessageStyle.LoginBanner && id) {
            const closedIds = [...closedBannersIds, id];
            this.handleSaveClosedMessageList(style, closedIds);
            this.setState({ loginBanners: loginBanners.filter(l => l.id !== id), closedBannersIds: closedIds });
        }
    }

    render() {
        const { user, type } = this.props;
        const { banners, popups, loginBanners, loginMessages } = this.state;
        const allBanners = [...loginBanners, ...banners];

        return (
            <>
                {
                    type === SystemMessageStyle.Login ?
                        <div>
                            <ComponentCarousel
                                arrows
                                dots={false}
                                draggable={false}
                                className={styles.loginCarousel}
                                infinite={false}
                            >
                                {loginMessages.map((m, i) => <SystemMessageItem message={m} key={i} />)}
                            </ComponentCarousel>
                        </div>
                        :
                        user.isLoggedIn ?
                            <div>
                                {
                                    allBanners.length > 0 &&
                                    <SystemMessageItem message={allBanners[0]} remaining={allBanners.length - 1} onClose={this.handleCloseSystemMessage} />
                                }

                                <Modal
                                    centered
                                    open={popups.length > 0}
                                    footer={null}
                                    onCancel={() => this.handleCloseSystemMessage(SystemMessageStyle.Popup)}
                                    width={760}
                                    styles={{ body: { paddingInline: popups.length > 1 ? 32 : 24 } }}
                                >
                                    <ComponentCarousel
                                        arrows
                                        dots={false}
                                        draggable={false}
                                        className={styles.modalCarousel}
                                        infinite={false}
                                    >
                                        {popups.map(p => <SystemMessageItem message={p} onClose={this.handleCloseSystemMessage} key={p.id} />)}
                                    </ComponentCarousel>
                                </Modal>
                            </div>
                            :
                            <div>
                                {
                                    loginBanners.length > 0 &&
                                    <SystemMessageItem message={loginBanners[0]} remaining={loginBanners.length - 1} onClose={this.handleCloseSystemMessage} />
                                }
                            </div>
                }
            </>

        );
    }
}

export default SystemMessageList;