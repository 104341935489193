// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { DatePicker } from 'antd';
import { dayjs, Dayjs } from 'translations';
import styles from './styles/date-picker-editor.less';
import { PickerRef } from "rc-picker/es/interface";

interface Props<T> extends ICellEditorParams {
    required?: boolean | undefined;
    disabledDate?: ((data: { currentDate: Dayjs, data: T }) => boolean) | undefined;
    getDefault?: (() => [Dayjs, Dayjs] | undefined) | undefined;
}

interface State {
    value: [Dayjs, Dayjs] | undefined;
    required: boolean;
    open: boolean;
}

class DateRangePickerEditor<T> extends Component<Props<T>, State> {
    datePickerRef = React.createRef<PickerRef>();

    constructor(props: Props<T>) {
        super(props);

        this.state = {
            open: false,
            value: props.value === null ? (props.getDefault === undefined ? undefined : props.getDefault()) : [dayjs.utc(props.value[0]), dayjs.utc(props.value[1])],
            required: this.props.required === undefined || this.props.required
        };

        setTimeout(() => this.setState({ open: true }), 10);
    }

    getValue() {
        return this.state.value === undefined ? undefined : [this.state.value[0], this.state.value[1].format()];
    }

    isPopup() {
        return true;
    }

    handleChange = (date: any | null) => {
        this.setState({ value: date === null ? undefined : date }, () => this.props.stopEditing());
    }

    afterGuiAttached() {
        const { column } = this.props;
        this.datePickerRef.current?.focus();
        this.datePickerRef.current?.nativeElement?.style.setProperty("width", `${column.getActualWidth()}px`);
    }

    isCancelAfterEnd() {
        return (this.state.required && this.state.value === undefined)
    }

    handleDisabledDate = (currentDate: Dayjs): boolean => {
        if (this.props.disabledDate)
            return this.props.disabledDate({ currentDate, data: this.props.data });
        return false
    }

    render() {
        return (
            <DatePicker.RangePicker
                disabledDate={this.handleDisabledDate}
                defaultValue={this.state.value}
                open={this.state.open}
                onOpenChange={(e) => this.setState({ open: e })}
                defaultOpen={true}
                format={"l"}
                ref={this.datePickerRef}
                onChange={this.handleChange}
                className={styles.date}
                rootClassName="ag-custom-component-popup ag-theme-balham ag-cell-inline-editing"
            />
        );
    }
}

export default DateRangePickerEditor;
