// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.

//Use this class to modify the url history so that we can more easily replace this function with client-side routing later.
class HistoryWrapper {
    static pushState(page: string, title: string, url: string) {
        window.history.pushState({ page: page }, title, url);
    }
    static go() {
        window.history.go();
    }
}


export default HistoryWrapper;