// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import { Col, InputNumber, Row } from "antd";
import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { ComparisonType } from "web-service";
import { filterConstants, FilterInputProps, FilterValueNumeric } from "../data";
import FilterListInputLabel from "./filter-list-input-label";

interface Props extends FilterInputProps<number> {
    table?: boolean;
}

interface State {
    visible: boolean;
}

class FilterListNumericSingle extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { values, showVisibilityToggle } = props;

        this.state = {
            visible: (showVisibilityToggle === undefined || showVisibilityToggle === false || (showVisibilityToggle && values !== undefined && values.length > 0))
        };
    }

    /**
     * Process input on change value
     * @param e Input value
     */
    handleChange = (inputValue: number | undefined, inputValue2: number | undefined, index: number) => {
        const { filter, values, onChange, defaultComparisonType } = this.props;
        const value = new FilterValueNumeric();

        const comparison = values && values.length > 0 ? values[0].comparisonType : defaultComparisonType ?? ComparisonType.Equal;

        if (inputValue) {
            // Correct value
            if (inputValue2 && inputValue2 < inputValue)
                inputValue2 = inputValue;

            value.assign(
                "",
                inputValue,
                inputValue2,
                comparison
            );
        }

        const newValues = cloneDeep(values ?? []);
        if (index >= 0)
            newValues[index] = value;

        if (onChange && filter)
            onChange(filter, newValues);
    };

    handleVisibilityChange = () => {
        const { filter, onChange, values } = this.props;
        const { visible } = this.state;

        const newVisible = !(visible || (values && values.length > 0));

        if (!newVisible && filter && onChange)
            onChange(filter, []);

        this.setState({ visible: newVisible });
    }

    render() {
        const { values, defaultComparisonType, table } = this.props;
        const { visible } = this.state;

        const trueValue = values ?? [];

        return (
            <Row align="middle">
                <FilterListInputLabel<number>
                    {...this.props}
                    open={visible || trueValue.length > 0}
                    onOpenChange={this.handleVisibilityChange}
                    options={filterConstants.numericListOptions}
                    table={table}
                />
                {
                    (visible || trueValue.length > 0) &&
                    <Col span={24}>
                        {
                            (values && values.length > 0 ? values[0].comparisonType : defaultComparisonType ?? ComparisonType.Equal) === ComparisonType.Range ?
                                <>
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        value={trueValue[0]?.value}
                                        min={0}
                                        onChange={(e) => this.handleChange(e === undefined ? e : Number(e), trueValue[0]?.value2, 0)}
                                        autoFocus
                                    />
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        value={trueValue[0]?.value2}
                                        min={trueValue[0]?.value}
                                        onChange={(e) => this.handleChange(trueValue[0]?.value, e === undefined ? e : Number(e), 0)}
                                    />
                                </>
                                :
                                <InputNumber
                                    style={{ width: "100%" }}
                                    value={trueValue[0]?.value}
                                    min={0}
                                    onChange={(e) => this.handleChange(e === undefined ? e : Number(e), undefined, 0)}
                                    autoFocus
                                />
                        }
                    </Col>
                }
            </Row>
        );
    }
}

export default FilterListNumericSingle;