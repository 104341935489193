// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Alert, Checkbox } from "antd";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ReservationWarning } from "web-service";

interface Props extends WithTranslation {
    reservationWarning?: ReservationWarning;
    hasReservations: boolean;
    mode: "delete" | "edit" | "add";
    checked: boolean;
    onChange: () => void;
}

class ReservationWarningView extends Component<Props> {
    render() {
        const { t, mode, reservationWarning, hasReservations } = this.props;

        if (reservationWarning) {
            const { entity, updateField, active } = reservationWarning;

            const showCheckbox = reservationWarning.showCheckbox || hasReservations;

            let updateText = "";

            if (mode === "edit") {
                if (active)
                    updateText = t("reservationWarningUpdateExistingText", { entity: entity });
            }

            return active ?
                <Alert
                    message={t("warning")}
                    description={
                        <div>
                            {(mode === "add") ?
                                <span>
                                    {t("reservationWarningAddText", { entity: entity })}
                                    {(showCheckbox) ? t("reservationWarningAddTextCheckbox", { updateField: updateField }) : t("reservationWarningNoCheckbox")}
                                </span>
                                : ""
                            }
                            {(mode === "edit") ?
                                <span>
                                    {updateText}
                                    {(showCheckbox) ? t("reservationWarningExistingTextCheckbox", { updateField: updateField }) : t("reservationWarningNoCheckbox")}
                                </span>
                                : ""
                            }
                            {(mode === "delete") ?
                                <span>
                                    {t("reservationWarningDeleteText", { entity: entity })}

                                    <span>
                                        {t("reservationWarningExisting", { entity: entity })}
                                        {(showCheckbox) ? t("reservationWarningExistingTextCheckbox", { updateField: updateField }) : t("reservationWarningNoCheckbox")}
                                    </span>
                                </span>
                                : ""
                            }
                            {(showCheckbox) ?
                                <div>
                                    <Checkbox checked={this.props.checked} onChange={() => {
                                        this.props.onChange();
                                    }}>
                                        {t("reservationWarningRecalculate")}
                                    </Checkbox>
                                </div>
                                : ""
                            }
                        </div>
                    }
                    type="error"
                />
                : "";
        } else {
            return null;
        }
    }
}

export default withTranslation(["event", "shared"])(ReservationWarningView);