// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
class Scroller {
    static scrollTo = (top: number) => {
        window.scrollTo({
            top,
            behavior: "smooth"
        })
    }
}

export { Scroller }