// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { ICellRendererParams } from "ag-grid-community";
import React, { Component } from "react";
import { Empty } from "antd";

interface Props extends ICellRendererParams {
    description: string;
}

class EmptyRenderer extends Component<Props> {  

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { description } = this.props;

        return <Empty description={description} />;
    }

}

export default EmptyRenderer;