// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import { DatePicker, DatePickerProps } from 'antd';
import React, { Component } from 'react';
import DatePickerEditor from './date-picker-editor';
import DateRangePickerEditor from './date-range-picker-editor';

class UiDatePicker extends Component<DatePickerProps> {
    constructor(props: DatePickerProps) {
        super(props);
    }

    render() {
        const { className, ...other } = this.props;

        return (
            <DatePicker {...other} className={className || ''} />
        );
    }
}

export default UiDatePicker;
export { DatePickerEditor, DateRangePickerEditor };
