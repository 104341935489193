// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Col, Drawer, Input, InputRef, Row, Select } from 'antd';
import { TFunction } from 'i18next';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MdChevronLeft, MdClose, MdSearch } from 'react-icons/md';
import { UserModel } from 'user';
import { EventStatus, serviceClient } from 'web-service';
import FeedbackDrawer from '../../drawers/feedback-drawer';
import { ManagePane, NotificationPane, ReportPane, SearchPane, UserPane } from '../../dropdowns';
import EventHeader from '../event';
import ClientMenu from './client-menu';
import EventMenu from './event-menu';
import styles from './styles/menu-drawer.less';

interface Props extends WithTranslation {
    user: UserModel;
    refreshUser: () => Promise<void>;
    setSwitchPane: (switchPane: (pane?: string) => void) => void;
    open: boolean;
    onClose: () => void;
    refreshSystemMessages: () => void;
    hasSystemMessages: boolean;
}

interface State {
    eventId: string | null;
    menuPane: string;
    search: string;
    searchByStatus: EventStatus;
    searchEvents: string;
    feedbackVisible: boolean;
}

const { Option } = Select;

class MenuDrawer extends Component<Props, State> {
    private eventSearch: React.RefObject<InputRef>;
    private search: React.RefObject<InputRef>

    constructor(props: Props) {
        super(props);

        this.state = {
            eventId: null,
            menuPane: 'main',
            search: '',
            searchByStatus: EventStatus.Live,
            searchEvents: '',
            feedbackVisible: false
        };    

        this.eventSearch = React.createRef<InputRef>();
        this.search = React.createRef<InputRef>();
        this.props.setSwitchPane(this.switchPane);
    }

    clearNotifications = () => {
        serviceClient.post("Clear", "Notification", {
            eventID: this.state.eventId
        }).promise.then(() => this.props.refreshUser());
    }

    switchPane = (menuPane? : string) => {
        this.setState({ menuPane: menuPane || 'main' });

        if (menuPane === 'search')
            setTimeout(() => this.search.current?.focus(), 1);    
        else if (menuPane === 'events')
            setTimeout(() => this.eventSearch.current?.focus(), 1);    
    }

    renderPane = (user: UserModel, refreshUser: () => Promise<void>, t: TFunction, pane: string, refreshSystemMessages: () => void, hasSystemMessages: boolean) => {
        switch (pane) {
            case 'main':
                return (
                    <menu>
                    {
                        user.event != null ?
                            <EventMenu user={user} switchPane={this.switchPane} />
                            :
                            ""
                    }
                    <ClientMenu user={user} refreshUser={refreshUser} switchPane={this.switchPane} />
                </menu>);
            case 'reports':
                return (<menu className={styles.subPane + " " + styles.subPaneMenu}>
                    <Button type="link" size="large" style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} onClick={() => this.switchPane('main')}><MdChevronLeft /> {t('reports')}</Button>
                    <ReportPane categories={true} user={user} />
                </menu>);
            case 'manage':
                return (<menu className={styles.subPane + " " + styles.subPaneMenu}>
                    <Button type="link" size="large" style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} onClick={() => this.switchPane('main')}><MdChevronLeft /> {t('event')}</Button>
                    <ManagePane user={user} />
                </menu>);
            case 'search':
                return (<menu className={styles.subPane + " " + styles.subPaneSearch}>
                    <Button type="link" size="large" style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} onClick={() => this.switchPane('main')}><MdChevronLeft /> {t('search')}</Button>
                    <div className={styles.subPaneSearchInput}>
                        <Input
                            prefix={<MdSearch className={styles.inputIcon} />}
                            placeholder={t('searchPlaceholder')}
                            size="large"
                            onChange={this.searchChanged}
                            onClick={(e) => e.preventDefault()}
                            ref={this.search}
                            addonAfter={(
                                <Select
                                    defaultValue={EventStatus.Live}
                                    className={styles.searchSelect + " select-after"}
                                    popupClassName={styles.searchSelectDropdown}
                                    popupMatchSelectWidth={140}
                                    onChange={(value) => this.setState({ searchByStatus: value as EventStatus })}
                                >
                                    <Option value="">{t('allEvents')}</Option>
                                    <Option value={EventStatus.Live}> {t('liveEvents')}</Option>
                                    <Option value={EventStatus.Preview}>{t('previewAndDemoEvents')}</Option>
                                    <Option value={EventStatus.Archived}>{t('archivedEvents')}</Option>
                                </Select>
                            )}
                        />
                    </div>
                    <SearchPane user={user} filter={this.state.search} eventStatus={this.state.searchByStatus}/>
                </menu>);
            case 'notifications':
                return (<menu className={styles.subPane + " " + styles.subPaneSearch}>
                    <Button type="link" size="large" style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} onClick={() => { this.switchPane('main'); this.clearNotifications(); }}><MdChevronLeft /> {t('notifications')}</Button>
                    <NotificationPane user={user} refreshSystemMessages={refreshSystemMessages} hasSystemMessages={hasSystemMessages} />
                </menu>);
            case 'user':
                return (<menu className={styles.subPane + " " + styles.subPaneMenu}>
                    <Button type="link" size="large" style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} onClick={() => this.switchPane('main')}><MdChevronLeft /> {t('user')}</Button>
                    <UserPane user={user}
                        showFeedback={() => this.setState({ feedbackVisible: true })} />
                    <FeedbackDrawer
                        open={this.state.feedbackVisible}
                        onClose={() => this.setState({ feedbackVisible: false })}
                        user={user} />
                </menu>);
            case 'events':
                return (<menu className={styles.subPane + " " + styles.subPaneSearch}>
                    <Button type="link" size="large" style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} onClick={() => this.switchPane('main')}><MdChevronLeft /> {t('search')}</Button>
                    <div className={styles.subPaneSearchInput}>
                        <Input
                            prefix={<MdSearch className={styles.inputIcon} />}
                            placeholder={t('searchEvents')}
                            size="large"
                            onChange={this.searchEventsChanged}
                            onClick={(e) => e.preventDefault()}
                            ref={this.eventSearch}
                        />
                    </div>
                    <SearchPane user={user} filter={this.state.searchEvents} type="Events" />
                </menu>);
        }

        return '';
    }

    searchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target != null) {
            let element = e.target as HTMLInputElement;

            this.setState({ search: element.value });
        }
    }

    searchEventsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target != null) {
            let element = e.target as HTMLInputElement;

            this.setState({ searchEvents: element.value });
        }
    }

    render() {
        const { t, user, refreshUser, open, onClose, refreshSystemMessages, hasSystemMessages } = this.props;
        return (
            <Drawer
                closable={false}
                open={open}
                onClose={onClose}
                width="95%"
                styles={{ body: { padding: 0 } }}
                drawerStyle={{ backgroundColor: user.styles.LOGOBACKGROUNDHEX, color: user.styles.LOGOTEXTHEX }}
                rootClassName={styles.drawerMobile}
                placement="left"
            >
                <header className={styles.headerClient} style={{ backgroundColor: user.styles.LOGOBACKGROUNDHEX, color: user.styles.LOGOTEXTHEX }}>
                    <Row align="middle" justify="space-between" className={styles.rowMenu}>
                        <Col>
                            <a href="/Client">
                                <img className={styles.logo} src={user.branding.logoUrl} alt="" />
                            </a>
                        </Col>
                        <Col className={styles.colMenuItems} onClick={onClose}>
                            <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem + " " + styles.navItemIcon + " " + styles.navClose} type="link" size="large" >
                                <MdClose size="small" />
                            </Button>
                        </Col>
                    </Row>
                </header>
                {
                    user.event != null ?
                        <EventHeader user={user} className={styles.headerEventInside} isTestSite={false} />
                        : ""
                }
                { this.renderPane(user, refreshUser, t, this.state.menuPane, refreshSystemMessages, hasSystemMessages) }
            </Drawer>
        );
    }
}


export default withTranslation('menu')(MenuDrawer);
