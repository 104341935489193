// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Select, RefSelectProps } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { serviceClient } from 'web-service';
import SearchSelectItem, { SelectModel } from './interfaces/search-select-item';
import { SearchSelectProps } from './interfaces/search-select-props';

interface State {
    page: number;
    hasMore: boolean;
    filter: string;
    items: Array<SearchSelectItem>;
    queryData: any;
    loading: boolean;
}

class SearchSelect extends Component<SearchSelectProps, State> {

    timeout: NodeJS.Timeout | null = null;
    selectRef: React.RefObject<RefSelectProps>;

    constructor(props: SearchSelectProps) {
        super(props);

        this.state = {
            page: 0,
            hasMore: true,
            filter: '',
            items: props.defaultValue === undefined ? [] : (Array.isArray(props.defaultValue) ? props.defaultValue : [props.defaultValue]),
            queryData: props.getData ? props.getData() : {},
            loading: false
        };

        this.selectRef = React.createRef();
        this.getMore();
    }

    componentWillUnmount = () => {
        this.clearTimeout();
    }

    focus = () => {
        this.selectRef.current?.focus();
    }

    componentDidUpdate = () => {
        const { queryData, items } = this.state;
        const { value } = this.props;

        const newData = this.props.getData ? this.props.getData() : {};

        if (!_.isEqual(queryData, newData)) {
            this.setState({
                page: 0,
                items: [],
                hasMore: true,
                queryData: newData
            }, () => this.getMore());
        }

        // add selected value if not in list
        if (value && !Array.isArray(value) && value?.value && !items.find((listItem) => _.isEqual(listItem?.value, value.value))) {
            this.setState({
                items: [value, ...items]
            });
        }
    }

    getMore = () => {
        this.clearTimeout();

        const serverQuery = () => {
            const { defaultValue, value, onChange } = this.props;
            let { filter, items, page } = this.state;
            this.setState({ loading: true });

            const data = Object.assign({}, this.state.queryData);

            data.page = page;
            data.filter = filter;

            const finalValue = value ?? defaultValue;

            if (this.props.action && this.props.controller) {
                serviceClient.post(this.props.action, this.props.controller, data)
                    .promise
                    .then((result: SelectModel) => {
                        const newItems: Array<SearchSelectItem> = (data.page === 0) ? result.items : items.concat(result.items);

                        if (finalValue) {
                            if (Array.isArray(finalValue)) {
                                finalValue.forEach((value) => {
                                    if (!newItems.find((listItem) => { return _.isEqual(listItem.value, value.value) })) {
                                        newItems.unshift(value);
                                    }
                                })
                            } else {
                                if (!newItems.find((listItem) => { return _.isEqual(listItem.value, finalValue.value) })) {
                                    newItems.unshift(finalValue);
                                }
                            }

                            if (onChange)
                                onChange(finalValue);

                        }

                        this.setState({
                            items: newItems,
                            page: page + 1,
                            hasMore: result.more,
                            loading: false
                        });
                    });
            } else {
                this.setState({ loading: false })
            };
        }

        this.timeout = setTimeout(serverQuery, 300);
    }

    clearTimeout = () => { 
        if(this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    convertValue(values: Array<SearchSelectItem> | SearchSelectItem | undefined): any {

        if (!values) {
            return undefined;
        }
        else if (Array.isArray(values))
        {
            if (values.length > 0)
                return values.map((v) => v.value);
            else
                return undefined;
        }
        else {
            return values.value;
        }
    }

    handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement;

        const isEndOfList = Math.ceil(target.scrollTop + target.clientHeight);

        if (isEndOfList >= target.scrollHeight) {

            if (this.state.hasMore)
                this.getMore();
        }
    }

    handleSearch = (filter: string) => {
        this.setState({
            filter: filter, page: 0, items: []
        }, () => {
            this.getMore();
        });
    }

    handleChange = (item: string | number | string[], option: any) => {
        const { onChange, mode } = this.props;
        const { filter, items } = this.state;

        if (onChange !== undefined) {
            if (Array.isArray(option)) {
                const values = option.map((o, ix) => {
                    let value = items.find(i => i.value === o.value)!;

                    // Append entered value
                    if (!value && mode === "tags" && Array.isArray(item))
                        value = { name: item[ix], value: item[ix], disabled: false };

                    return value;
                });
                onChange(values);
            } else {
                if (option.value) {
                    onChange(items.find(i => i.value === option.value)!);
                }
                else {
                    const found = items.find(i => i.value === item);
                    if (found)
                        onChange(found);
                    else
                        //Pass empty array as cannot pass undefined in case empty description or an item that couldn't be found is selected
                        onChange([]);
                }

            }

            //Reset
            if (filter && filter.length > 0) {
                this.setState({
                    page: 0,
                    hasMore: true,
                    filter: '',
                }, () => {
                    this.getMore();
                });
            }

        }
    }

    render() {
        const { emptyDescription, className, style, showSearch, tabIndex, mode, defaultValue, popupClassName, disabled, getPopupContainer, value, placeholder, size, tagRender } = this.props;
        const { items, loading } = this.state;

        return (
            <Select
                loading={loading}
                placeholder={placeholder}
                getPopupContainer={getPopupContainer}
                disabled={disabled}
                showAction={['focus', 'click']}
                ref={this.selectRef}
                mode={mode}
                tabIndex={tabIndex}
                showSearch={showSearch}
                filterOption={false}
                popupClassName={popupClassName}
                className={className}
                style={style}
                size={size}
                notFoundContent={null}
                defaultValue={this.convertValue(defaultValue)}
                value={this.convertValue(value)}
                onPopupScroll={this.handleScroll}
                onSearch={showSearch ? this.handleSearch : undefined}
                tagRender={tagRender}        
                onChange={this.handleChange}>
                {(emptyDescription === undefined) ? "" : (<Select.Option key="" value="">{emptyDescription}</Select.Option>)}
                {items.map((item) =>
                    <Select.Option key={item.name + item.value} value={item.value} disabled={item.disabled}>{item.name}</Select.Option>
                )}
            </Select>
        );
    }
}

export default SearchSelect;
