// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import './styles/ag-theme.scss';
import { AgGridReact } from 'ag-grid-react';
import { CellClassParams } from 'ag-grid-community';

const getDefaultCellClassRules = () => {  
    const result: any = {};
    
    //If cell editable, add class.
    result["grid-cell-editable"] = (params: CellClassParams) => {

        if (params.colDef) {
            const editable = params.colDef.editable;

            if (editable instanceof Function) {
                return editable({
                    node: params.node,
                    data: params.data,
                    column: params.context,
                    colDef: params.colDef,
                    context: params.context,
                    api: params.api,
                    columnApi: params.columnApi
                });
            }
            else {
                return editable == true;
            }
        }

        return false;
    };

    return result;
}


export { getDefaultCellClassRules };
export default AgGridReact;