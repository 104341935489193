// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
interface SystemMessage {
    id: string;
    systemMessageStyle: SystemMessageStyle;
    header: string;
    notificationMessage: string;
    buttonText: string | undefined;
    buttonLink: string | undefined;
    bannerStatus: BannerStatus | undefined;
    imageURLs: string[]
}

enum SystemMessageStyle {
    Login,
    LoginBanner,
    Banner,
    Popup
}

enum BannerStatus {
    Informational = 0,
    Critical,
    Success,
    Important
}

export { SystemMessageStyle, BannerStatus };
export type { SystemMessage };