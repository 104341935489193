// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Menu, Row, Col, Button, Badge, Input, Avatar } from 'antd';
import { MdSearch, MdNotifications } from 'react-icons/md';
import { permissionLookup } from 'security';

import styles from './styles/client-menu.less';
import { UserModel } from 'user';

const { Item } = Menu;

interface Props extends WithTranslation {
    user: UserModel;
    refreshUser: () => Promise<void>;
    switchPane: (pane: string) => void;
}

interface State {

}

class ClientMenu extends Component<Props, State> {
    constructor(props : Props) {
        super(props);
    }

    render() {
        const { t, user, refreshUser, switchPane } = this.props;

        return (
            <div> 
                <Row align="middle" justify="space-between" className={styles.rowMenu}>
                    <Col className={styles.colSearch}>
                        <Input
                            onClick={() => switchPane('search')}
                            prefix={<MdSearch className={styles.inputIcon} />}
                            placeholder={t('searchPlaceholder')}
                        />
                    </Col>
                    <Col className={styles.colMenuItems}>
                        <Button
                            onClick={() => switchPane('notifications')}
                            style={{ color: user.styles.LOGOTEXTHEX }}
                            className={styles.navItem + " " + styles.navItemIcon}
                            type="link" size="large">
                            <Badge
                                count={user.newNotifications > 0 ? user.newNotifications : ""}
                                className={styles.badge}
                                style={{ boxShadow: "0 0 0 2px " + user.styles.LOGOBACKGROUNDHEX }}
                                offset={[7, -7]}
                                overflowCount={9}
                            >
                                <MdNotifications />
                            </Badge>
                        </Button>
                        <Button
                            onClick={() => switchPane('user')}
                            style={{ color: user.styles.LOGOTEXTHEX }}
                            type="link"
                            className={styles.navItemAvatar}
                            size="large">
                            {user.avatarUrl === null ?
                                (
                                    <Avatar style={{ backgroundColor: user.styles.LOGOTEXTHEX, color: user.styles.LOGOBACKGROUNDHEX }} className={styles.avatar + " " + styles.avatarText}>{user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}</Avatar>
                                ) : (
                                    <Avatar src={user.avatarUrl} className={styles.avatar}></Avatar>
                                )
                            }
                        </Button>
                    </Col>
                </Row>
                <Menu
                    mode="inline"
                    className={styles.mobileMenu}
                >  
                    <Item key="1" className={styles.mobileMenuItem}>
                        <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" size="large" href="/Client">{t('clientHome')}</Button>
                    </Item>
                    <Item key="2" className={styles.mobileMenuItem}>
                        <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" size="large" onClick={() => switchPane('events')}>{t('events')}</Button>
                    </Item>
                    {user.clientPermissions.includes(permissionLookup.EVENT_MANAGEMENT) ? (
                        <Item key="3" className={styles.mobileMenuItem}>
                            <Button style={{ color: user.styles.LOGOTEXTHEX }} className={styles.navItem} type="link" size="large" href={'/Client/Wizard'}>{t('createNewEvent')}</Button>
                        </Item>
                    ) : ""}                    
                </Menu>
            </div>
        );
    }
}


export default withTranslation('menu')(ClientMenu);
