// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import React, { Component, ReactNode } from "react";
import { Collapse, CollapseProps } from "antd";
import { ItemType } from "rc-collapse/es/interface";
import styles from "./styles/index.less";
import clsx from "clsx";

interface BaseProps extends Omit<CollapseProps, "items" | "children"> {
    multiple?: boolean;
    expand?: boolean;
    title?: ReactNode;
    onChange?: () => void;
    style?: React.CSSProperties;
    className?: string;
    forceRender?: boolean;
}

type PropsWithoutItems = BaseProps & { items?: never; children: ReactNode };
type PropsWithItems = BaseProps & { children?: never; items: ItemType[] };

type Props = { multiple: true; } & PropsWithItems |
    { multiple?: false; } & PropsWithoutItems;

class CollapseHeight extends Component<Props> {
    render() {
        const { multiple, expand, activeKey, title, children, items, onChange, style, className, forceRender, ...rest } = this.props;
        return (
            <Collapse
                ghost
                style={style}
                className={clsx([styles.collapse, !title && styles.noTitle, className])}
                bordered={false}
                onChange={onChange}
                activeKey={multiple ? activeKey : expand ? "1" : undefined}
                items={multiple ? items : [{
                    key: "1",
                    showArrow: false,
                    forceRender,
                    label: title,
                    children
                }]}
                {...rest}
            />
        )
    }
}

export default CollapseHeight;
