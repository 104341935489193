// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { ICellRendererParams } from "ag-grid-community";
import { Checkbox } from "antd";
import React, { Component } from "react";

interface Props extends ICellRendererParams {
    disabled?: boolean;
    getDisabled?: () => boolean;
}

class CheckboxRenderer extends Component<Props> {  

    constructor(props: ICellRendererParams) {
        super(props);

        this.state = {
            value: props.value,
        }
    }

    render() {
        const { setValue, value, disabled, getDisabled } = this.props;

        const finalDisabled = disabled || (getDisabled && getDisabled());

        return (<Checkbox disabled={finalDisabled} checked={value} onChange={(e) => setValue?.(e.target.checked)} />)
    }

}

export default CheckboxRenderer;