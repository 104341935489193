// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

class ApplicationInsightsWrapper {
    private appInsights: ApplicationInsights | null = null;

    init(telemetryKey: string) {
        if (this.appInsights === null) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: telemetryKey
                }
            });
            this.appInsights.loadAppInsights();
            this.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview    
        }
    }

    getAppInsights() {
        return this.appInsights;
    }
} 

export default new ApplicationInsightsWrapper();