// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import Icon from "@ant-design/icons";
import { Modal, Typography } from "antd";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";


const { Title, Text } = Typography;

interface Props extends WithTranslation {
    open: boolean;
    onOk: () => void;
    onCancel: () => void;
}

class FilterConfirmation extends Component<Props> {
    render() {
        const { t, open, onOk, onCancel } = this.props;

        return (
            <Modal
                title={<Title level={4}>{t("confirmFilters")}</Title>}
                width={640}
                closeIcon={<Icon component={IoClose} />}
                cancelButtonProps={{
                    ghost: true
                }}
                open={open}
                onOk={onOk}
                onCancel={onCancel}
                okText={t("applyFilters")}
                cancelText={t("dontApply")}
                centered
            >
                <Text>{t("filtersChanged")}</Text>
            </Modal>
        );
    }
}

export default withTranslation(["ui"])(FilterConfirmation);