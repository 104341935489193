// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Dropdown } from "antd";
import React, { Component } from "react";
import { ButtonGridProps, DropdownButtonGridProps, isButtonGrid } from "./interfaces/button-props";

// TODO: Test this
class ButtonRenderer extends Component<ButtonGridProps | DropdownButtonGridProps> {  

    constructor(props: ButtonGridProps | DropdownButtonGridProps) {
        super(props);
    }

    render() {
        const { onClick, className, getDisabled } = this.props;

        const childProps = isButtonGrid(this.props) ? (this.props as ButtonGridProps).buttonProps : (this.props as DropdownButtonGridProps).dropdownProps;
        const disabled = childProps.disabled || (getDisabled && getDisabled());

        return (
            <span className={className}>
                {
                    isButtonGrid(this.props) ?
                        <Button
                            disabled={disabled}
                            {...childProps}
                            onClick={() => onClick(this.props)}
                        />
                        :
                        <Dropdown.Button
                            disabled={disabled}
                            {...childProps}
                            onClick={() => onClick(this.props)}
                            menu={"menu" in childProps ? childProps?.menu : undefined}
                        />
                }
            </span>
        );
    }
}

export default ButtonRenderer;