// Copyright � 2019 Streampoint Solutions Inc. All rights reserved.
import { ComparisonType, Filter } from "web-service";
import React from "react";

class FilterValue<T> implements Filter<T> {
    constructor(value?: T, type?: ComparisonType, value2?: T) {
        this.value = value;
        this.value2 = value2;
        this.comparisonType = type === undefined ? ComparisonType.Equal : type;
    }

    label?: React.ReactNode;
    value: T | undefined;
    value2: T | undefined;
    comparisonType: ComparisonType;

    assign(label: React.ReactNode, value: T | undefined, value2: T | undefined, comparisonType: ComparisonType): FilterValue<T> {
        this.label = label;
        this.value = value;
        this.value2 = value2;
        this.comparisonType = comparisonType;

        return this;
    }

    setComparison(comparison: ComparisonType) {
        this.comparisonType = comparison;

        if (comparison !== ComparisonType.Range)
            this.value2 = undefined;

        return this;
    }
}

export { FilterValue };
