// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { ColDef, FirstDataRenderedEvent, GridApi, GridOptions, SelectionChangedEvent } from "ag-grid-community";
import { Button, Checkbox, Col, Form, FormInstance, FormRule, Input, Modal, Row, Select, Tooltip } from "antd";
import { Store, StoreValue } from "antd/es/form/interface";
import { constants } from "configuration";
import React, { Component, createRef, RefObject } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MdInfo } from "react-icons/md";
import Grid, { getDefaultCellClassRules } from "ui/grid";
import { UserModel } from "user";
import { Currency, EventTax, EventTaxAppliedAt, TaxLevel, TaxType } from "web-service";
import ReservationWarningView from "../reservation-warning-view";
import { numberValidator } from "../validators";
import { PendingEventTax } from "./interfaces/event-taxes";
import { EventTaxAmountRenderer, EventTaxNameRenderer } from "./renderers";
import styles from "./styles/tax-list.less";

const { Option } = Select;

enum DisplayType {
    Hotel,
    SubBlock
}

interface Props extends WithTranslation {
    type: DisplayType;
    user: UserModel;
    selectedEventTax: PendingEventTax | undefined;
    taxes: Array<PendingEventTax>,
    updateTax: (tax: PendingEventTax, updateReservationDeposits: boolean) => void;
    deleteTax: (tax: PendingEventTax, updateReservationDeposits: boolean) => void;
    cancelTax: () => void;

    currency: Currency;
    processing?: boolean;
}

interface State {
    activeTax?: PendingEventTax;
    initialValues?: Store;
    reservationWarningChecked: boolean;
    showDeleteModal: boolean;
    gridOptions: GridOptions;
    appliedFeeTaxes: Array<any>;
    feeIsTaxable: boolean;
}

class FeeEdit extends Component<Props, State> {
    formRef: RefObject<FormInstance> = createRef();
    selectedGridApi?: GridApi;

    constructor(props: Props) {
        super(props);

        const { selectedEventTax, t, user } = this.props;

        const columnDefs: ColDef[] = [
            {
                checkboxSelection: (params) => true,
                headerCheckboxSelection: true,
                headerName: t("taxes"),
                cellRenderer: "EventTaxNameRenderer",
                cellRendererParams: { hideLockIcon: true },
                cellStyle: {
                    fontWeight: 500
                },
                valueSetter: (param) => { return true; },
                valueGetter: (param) => { return true; }
            },
            {
                headerName: t("rate"),
                cellStyle: {
                    fontWeight: 500,
                },
                cellRenderer: "EventTaxAmountRenderer",
            },
        ];

        this.state = {
            activeTax: selectedEventTax ?? undefined,
            reservationWarningChecked: (selectedEventTax?.eventTax.reservationWarning?.updateReservations === true),
            showDeleteModal: false,
            appliedFeeTaxes: [],
            feeIsTaxable: (selectedEventTax?.eventTax.appliedFeeTaxes?.length ?? 0) > 0,
            gridOptions: {
                rowSelection: "multiple",
                groupSelectsChildren: true,
                suppressRowClickSelection: true,
                stopEditingWhenGridLosesFocus: true,
                columnDefs: columnDefs,
                defaultColDef: {
                    sortable: false,
                    unSortIcon: true,
                    filter: false,
                    flex: 1,
                    minWidth: 125,
                    resizable: true,
                    editable: false,
                    suppressMenu: true,
                    suppressMovable: true,
                    cellClassRules: getDefaultCellClassRules(),
                },
                noRowsOverlayComponent: "EmptyRenderer",
                noRowsOverlayComponentParams: {
                    description: t("noneAvailable")
                },
                onSelectionChanged: this.handleSelectChanged,
                frameworkComponents: {
                    EventTaxNameRenderer,
                    EventTaxAmountRenderer
                },
            }
        };

    }

    componentDidMount = () => {
        this.updateInitialValue();
    }

    componentDidUpdate = (prevProps: Props) => {
        if (this.props.selectedEventTax !== prevProps.selectedEventTax)
            this.updateInitialValue();
    }

    updateInitialValue = () => {

        const { activeTax } = this.state;

        if (activeTax) {
            const eventTax = activeTax.eventTax

            const newValues = {
                name: eventTax?.translation.name,
                rateType: eventTax?.isPercent ? "true" : "false",
                amount: eventTax?.amount,
                level: eventTax?.level,
                perOccupancy: (!eventTax?.isPercent) ? eventTax?.perOccupancy : false,
                hotelMandatory: !eventTax?.hotelSpecific,
                appliedTo: eventTax?.appliedTo === EventTaxAppliedAt.Deposit,
                description: eventTax?.translation.description
            };
            this.setState({
                initialValues: newValues
            }, () => {
                this.formRef.current?.resetFields();
            });
        }
    }

    getInitialValueModel = (values: Store) => {
        const { type } = this.props;

        return {
            translation: {
                name: values.name,
                description: values.description
            },
            isPercent: false,
            amount: values.amount,
            level: values.level,
            perOccupancy: values.perOccupancy,
            hotelSpecific: (type === DisplayType.SubBlock ? false : !values.hotelMandatory),
            appliedTo: values.appliedTo ? EventTaxAppliedAt.Deposit : EventTaxAppliedAt.Total,
            taxType: (type === DisplayType.Hotel ? TaxType.HotelFee : TaxType.SubBlockFee)
        };
    }

    handleFeeUpdate = async () => {
        const { updateTax, selectedEventTax } = this.props;
        const { reservationWarningChecked, gridOptions, feeIsTaxable } = this.state;

        const formValues = await this.formRef.current?.validateFields()
            .catch(() => {
                return undefined;
            });

        if (formValues && selectedEventTax) {
            const modifiedFee: EventTax = Object.assign(selectedEventTax?.eventTax, this.getInitialValueModel(formValues));

            modifiedFee.appliedFeeTaxes = feeIsTaxable ?
                gridOptions.api?.getSelectedNodes().map(n => n.data.eventTax)
                : []

            if (modifiedFee.reservationWarning) {
                modifiedFee.reservationWarning.updateReservations = reservationWarningChecked;
            }

            selectedEventTax.eventTax = modifiedFee;

            updateTax(selectedEventTax, reservationWarningChecked);
        }
    }

    reservationWarningGetMode = () => {
        const { activeTax } = this.state;

        if (activeTax) {
            if (activeTax.eventTax.id && activeTax.eventTax.id !== constants.GUID_EMPTY)
                return "edit";
        }

        return "add";
    }

    handleReservationWarningOnChange = () => {
        this.setState({
            reservationWarningChecked: !this.state.reservationWarningChecked
        });
    }

    handleSelectChanged = (e: SelectionChangedEvent) => {
        this.setState({ appliedFeeTaxes: e.api.getSelectedNodes().map(n => n.data) });
    }

    onFirstDataRendered = (params: FirstDataRenderedEvent) => {
        const { selectedEventTax } = this.props;
        params.api.forEachNode(node => {
            const eventTaxExist = !!(selectedEventTax?.eventTax.appliedFeeTaxes?.find(eventTax => eventTax.translation.name === node.data.eventTax.translation.name))
            node.setSelected(eventTaxExist);
        });
    }

    render() {
        const { t, deleteTax, processing, taxes, type } = this.props;
        const { activeTax, initialValues, reservationWarningChecked, gridOptions, feeIsTaxable } = this.state;
        const { handleFeeUpdate, handleReservationWarningOnChange, formRef, onFirstDataRendered } = this;

        const isExistingTax = (activeTax && (activeTax.eventTax.id !== constants.GUID_EMPTY || activeTax.modified !== false));
        if (activeTax?.eventTax.reservationWarning?.entity)
            activeTax.eventTax.reservationWarning.entity = t("fee");

        return (
            <Form
                ref={formRef}
                layout="vertical"
                initialValues={initialValues ?? {}}
            >
                <div className={styles.taxWarning}>
                    <ReservationWarningView
                        checked={reservationWarningChecked}
                        onChange={handleReservationWarningOnChange}
                        reservationWarning={activeTax?.eventTax.reservationWarning}
                        mode={this.reservationWarningGetMode()}
                        hasReservations={false}
                    />
                </div>

                <Row className={styles.formGutter}>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            name="name"
                            label={t("taxFeeName")}
                            rules={[
                                {
                                    required: true,
                                    message: t("shared:validationRequired", { field: t("feeNameTitle") }),
                                },
                            ]}
                        >
                            <Input disabled={processing} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            name="description"
                            label={t("description")}
                        >
                            <Input disabled={processing} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={4}>
                        <Form.Item
                            name="amount"
                            label={t("amount")}
                            rules={[
                                {
                                    required: true,
                                    message: t("shared:validationRequired", { field: t("amount") }),
                                },
                                { validator: (rule: FormRule, value: StoreValue) => numberValidator(rule, value, t, false, 0.01) }
                            ]}
                        >
                            <Input disabled={processing} addonBefore={this.props.currency.symbol} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={styles.formGutter}>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            name="level"
                            label={t("feeAppliedType")}
                            rules={[
                                {
                                    required: true,
                                    message: t("shared:validationRequired", { field: t("taxFeeAppliedType") })
                                },
                            ]}
                        >
                            <Select disabled={processing}>
                                <Option value={TaxLevel.PerRoomNight}>{t("taxPerRoomNight")}</Option>
                                <Option value={TaxLevel.PerReservation}>{t("taxPerReservation")}</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={16}>
                        <Row className={styles.feeCheckList}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    name="perOccupancy"
                                    valuePropName="checked"
                                >
                                    <Checkbox disabled={processing}>
                                        {t("applyThisFeeForEachRoomOccupant")}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            {
                                type == DisplayType.Hotel &&
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        name="hotelMandatory"
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={processing}>
                                            {t("feeHotelSpecific")}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            }
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    name="appliedTo"
                                    valuePropName="checked"
                                >
                                    <Checkbox disabled={processing}>
                                        {t("feeChargeReservation")}
                                        <Tooltip title={t("feeChargeReservationText")} trigger="hover">
                                            <MdInfo />
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Checkbox
                                    checked={feeIsTaxable}
                                    disabled={processing}
                                    onChange={() => this.setState({ feeIsTaxable: !feeIsTaxable })}>
                                    {t("feeIsTaxable")}
                                </Checkbox>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {feeIsTaxable &&
                    <div>
                        <p><label>{t("applicableFeeTaxes")}</label></p>
                        <div className={"ag-theme-balham " + styles.grid + " " + styles.gridAvailable + " " + styles.taxList}>
                            <Grid
                                gridOptions={gridOptions}
                                rowData={taxes}
                                onFirstDataRendered={onFirstDataRendered}
                            />
                        </div>
                    </div>
                }
                <div className={styles.taxButtons}>
                    <div>
                        <Button
                            onClick={this.props.cancelTax}
                            block
                        > {t("cancel")}
                        </Button>
                    </div>
                    <div>
                        {(isExistingTax)
                            &&
                            <Button disabled={processing} onClick={() => { this.setState({ showDeleteModal: true }); }} danger>
                                {t("deleteFee")}
                            </Button>
                        }
                        <Button disabled={processing} type="primary" onClick={handleFeeUpdate} block>
                            {(isExistingTax) ? t("save") : t("createNewTaxFeeButton")}
                        </Button>

                    </div>
                </div>

                <Modal
                    title={t("deleteFee")}
                    open={this.state.showDeleteModal}
                    onOk={() => {
                        if (activeTax)
                            deleteTax(activeTax, reservationWarningChecked);
                    }}
                    okText={t("shared:yes")}
                    okType={"danger"}
                    okButtonProps={({ type: "primary" })}
                    onCancel={() => { this.setState({ showDeleteModal: false }); }}
                    cancelText={t("shared:no")}
                >
                    {t("deleteFeeText")}
                    <ReservationWarningView
                        checked={reservationWarningChecked}
                        onChange={handleReservationWarningOnChange}
                        reservationWarning={activeTax?.eventTax.reservationWarning}
                        mode={"delete"}
                        hasReservations={false}
                    />
                </Modal>
            </Form>
        )
    }
}

export default withTranslation(["event", "shared"])(FeeEdit);
export { DisplayType };
