// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
import { Button, Checkbox, Col, Empty, Form, Row, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { dayjs } from "translations";
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { EventSelect, MegaphoneIcon } from 'ui';
import { UserModel } from 'user';
import { serviceClient } from 'web-service';
import styles from './styles/notification-pane.less';

interface Props extends WithTranslation {
    user: UserModel;
    refreshSystemMessages: () => void;
    hasSystemMessages: boolean;
}

interface Notification {
    message: string;
    created: string;
    isNew: boolean;
}

interface State {
    page: number;
    hasMore: boolean;
    count: number;
    enabled: boolean;
    notifications: Array<Notification>;
    eventId: string;
}

class SearchPane extends Component<Props, State> {

    constructor(props : Props) {
        super(props);

        this.state = {
            page: 0,
            hasMore: true,
            count: 0,
            enabled: true,
            notifications: [],
            eventId: ''
        };

        this.queryApi(0, '', []);
    }

    queryApi = (page: number, eventId: string, notifications: Array<Notification>) => {
        serviceClient.post("List", "Notification", {
            page: page,
            eventID: eventId,
        }).promise.then(result => {
            this.setState({
                notifications: notifications.concat(result.items),
                page: page + 1,
                eventId: eventId,
                hasMore: result.more,
                count: result.count,
                enabled: result.enabled
            });
        });
        
    }

    getRelativeTime = (time: string) => {
        const created = dayjs(time);
        return created.fromNow();
    }

    getMore = () => {
        this.queryApi(this.state.page, this.state.eventId, this.state.notifications);
    }

    setEvent = (eventID: string) => {
        this.queryApi(0, eventID, []);
    }

    enableNotifications = (e: CheckboxChangeEvent) => {
        serviceClient.post("Enable", "Notification", {
            eventID: this.state.eventId
        }).promise.then(result => {
            this.setState({ enabled: e.target.checked });
            this.setEvent(this.state.eventId);
        });
    }

    render() {
        const { t, user, refreshSystemMessages, hasSystemMessages } = this.props;

        return (
            <div>
                <div className={styles.popoverHeader}>
                    <Row align="middle" justify="space-between">
                        <Col>
                            <span className={styles.menuListTitle}>{t("notifications")}</span>
                        </Col>
                        <Col>
                            <Tooltip title={t("showSystemMessages")}>
                                <Button
                                    disabled={!hasSystemMessages}
                                    icon={<MegaphoneIcon />}
                                    onClick={refreshSystemMessages}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </div>

                <div className={styles.popoverNotificationContent}>
                    <Row align="middle" justify="start" className={styles.rowSearch}>
                        <Col>
                            <span className={styles.searchTitle}>{t("filterByEvent")}</span>
                        </Col>
                        <Col className={styles.colSearch}>
                            <Form.Item style={{ margin: 0 }}><EventSelect getPopupContainer={(e: HTMLElement) => e.parentNode as HTMLElement} user={user} onChange={(e: any) => this.setEvent(e.value)} className={styles.eventSearch} emptyDescription={t('all')} /></Form.Item>
                        </Col>
                    </Row>

                    <div className={styles.popoverNotificationContentInner}>
                {
                    this.state.enabled ?
                    (
                        <InfiniteScroll
                                pageStart={0}
                                loadMore={this.getMore}
                                hasMore={this.state.hasMore}
                                useWindow={false}
                                initialLoad={false}
                                >

                                    {this.state.notifications.length > 0 ? this.state.notifications.map((item, i) => (
                                        <div key={i} className={styles.notification + " " + (item.isNew ? styles.newNotification : "")} dangerouslySetInnerHTML={{ __html: item.message.replace('<!--Time-->', this.getRelativeTime(item.created)) }} />
                                    )) :
                                            <Empty description={t('emptyNotifications')} />}
                        </InfiniteScroll>
                    ) :
                    (
                        <div className={styles.notificationsDisabled}>
                            <p>{t('notificationsDisabled')}</p>
                                        <Form.Item style={{ marginBottom: 0 }}>
                                            <Checkbox onChange={this.enableNotifications}>
                                        {t("enableNotifications")}
                                    </Checkbox>
                            </Form.Item>
                        </div>
                    )
                        }
                        </div>
                </div>
                <div className={styles.popoverFooter}>
                    <Row align="middle" justify="space-between" className={styles.notificationFooter}>
                        <Col><Button type='link' href={user.baseUrl + "/Manage/MyAccount"} className={styles.notificationManage}>{t('manageNotifications')}</Button></Col>
                        <Col><Button type='primary' href={user.baseUrl + "/Notification"}>{t('viewAllNotifications')}</Button></Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default withTranslation('menu')(SearchPane);