// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label__Ic9ku {
  position: absolute;
  top: -4px;
  right: 0;
  transform: translateY(-100%);
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
}
.label__Ic9ku > span {
  display: inline-block;
}
.label__Ic9ku.visible__e4NfT {
  opacity: 1;
}
.label__Ic9ku.hidden__g1kAE {
  opacity: 0;
}
.label__Ic9ku.floatStart__hAokO {
  top: -2px;
  transform: translateX(calc(100% + 8px));
}
.label__Ic9ku.floatStart__hAokO svg {
  width: 20px;
  height: 20px;
}
.label__Ic9ku.floatEnd__hDw59 {
  transform: none;
}
.contractIcon__vdbd6 {
  color: #21B0B7;
}
.gdsIcon___E25w {
  color: #FF6F5C;
}
`, "",{"version":3,"sources":["webpack://./Scripts/webpack/modules/ui/input-label/styles/block-icon-input-label.module.less"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,4BAAA;EACA,qCAAA;EACA,UAAA;AADJ;AALA;EASQ,qBAAA;AADR;AAII;EAAY,UAAA;AADhB;AAEI;EAAW,UAAA;AACf;AAAI;EACI,SAAA;EACA,uCAAA;AAER;AAJI;EAIQ,WAAA;EACA,YAAA;AAGZ;AAAI;EACI,eAAA;AAER;AAEA;EACI,cAAA;AAAJ;AAGA;EACI,cAAA;AADJ","sourcesContent":["@import \"../../../../apps/styles/style-variables.less\";\n\n.label {\n    position: absolute;\n    top: -4px;\n    right: 0;\n    transform: translateY(-100%);\n    transition: opacity 0.25s ease-in-out;\n    opacity: 0;\n\n    > span {\n        display: inline-block;\n    }\n\n    &.visible { opacity: 1; }\n    &.hidden { opacity: 0; }\n    &.floatStart {\n        top: -2px;\n        transform: translateX(calc(100% + 8px));\n        svg {\n            width: 20px;\n            height: 20px;\n        }\n    }\n    &.floatEnd {\n        transform: none;\n    }\n}\n\n.contractIcon {\n    color: @apolloColorNeon;\n}\n\n.gdsIcon {\n    color: @errorColorNeon;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `label__Ic9ku`,
	"visible": `visible__e4NfT`,
	"hidden": `hidden__g1kAE`,
	"floatStart": `floatStart__hAokO`,
	"floatEnd": `floatEnd__hDw59`,
	"contractIcon": `contractIcon__vdbd6`,
	"gdsIcon": `gdsIcon___E25w`
};
export default ___CSS_LOADER_EXPORT___;
