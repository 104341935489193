// Copyright © 2019 Streampoint Solutions Inc. All rights reserved.
class HotJarWrapper {
    isInitialized = false;

    init(hotJarSettings) {
        if (this.isInitialized)
            return;

        (function (h, o, t, j, a, r) {
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = JSON.parse("{" + hotJarSettings + "}");
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script'); r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

        this.isInitialized = true;
    }
} 

export default new HotJarWrapper();